import { Money } from "@saas/shared/utils";

import {
  getPromoPaymentMethodLabel,
  PromoGrantTypeEnum,
  PromoRuleTypeEnum,
  StorePromoRuleInterface,
} from "../../";

export type GetStorePromoRuleLabelInput = {
  rule?: StorePromoRuleInterface;
  minimumSalesAmount?: Money;
  grantType?: PromoGrantTypeEnum;
};

export type GetStorePromoRuleLabelOutput = string;

export const getStorePromoRuleLabel = ({
  rule,
  minimumSalesAmount,
  grantType,
}: GetStorePromoRuleLabelInput): GetStorePromoRuleLabelOutput => {
  const options =
    rule?.config.paymentMethodOptions?.map((option) =>
      getPromoPaymentMethodLabel(option)
    ) ?? [];
  const lastOption = options[options.length - 1];

  switch (rule?.label) {
    case PromoRuleTypeEnum.MINIMUM_ORDER_QUANTITY:
      return `Minimum Pembelian: ${rule?.config.minimumQuantity} barang`;

    case PromoRuleTypeEnum.MINIMUM_ORDER_SALES_AMOUNT:
      return minimumSalesAmount
        ? `Nominal Pembelian Minimum: ${minimumSalesAmount?.currency} ${minimumSalesAmount?.value}`
        : "";

    case PromoRuleTypeEnum.MINIMUM_PRODUCT_ORDER_QUANTITY:
      return grantType === PromoGrantTypeEnum.PROMO_GRANT_TYPE_ALL
        ? `${rule?.config.masterProduct?.name} ${rule?.config.masterProductVariant?.name}`
        : `Produk Utama: "${rule?.config.masterProductVariant?.name}"`;

    case PromoRuleTypeEnum.PAYMENT_METHOD:
      return options.length > 1
        ? `Pembayaran ${options
            .slice(0, options.length - 1)
            ?.join(", ")} & ${lastOption}`
        : options.length
        ? `Pembayaran ${options[0]}`
        : "";
    default:
      return "";
  }
};

export default getStorePromoRuleLabel;
