export enum PromoRuleTypeEnum {
  MINIMUM_ORDER_SALES_AMOUNT = "minimum_order_sales_amount",
  MINIMUM_ORDER_QUANTITY = "minimum_order_quantity",
  MINIMUM_PRODUCT_ORDER_QUANTITY = "minimum_product_order_quantity",
  PAYMENT_METHOD = "payment_method",
}

export enum PromoLabelEnum {
  MINIMUM_ORDER_SALES_AMOUNT = "minimum_order_sales_amount",
  MINIMUM_ORDER_QUANTITY = "minimum_order_quantity",
  PRODUCT_DISCOUNT = "product_discount",
  PRODUCT_BUY_N_GET_M = "product_buy_n_get_m",
  PRODUCT_BUNDLING = "product_bundling",
  PAYMENT_METHOD = "payment_method",
  LOYALTY_POINTS = "loyalty_points",
  VOUCHER = "voucher_code",
  ON_THE_FLY_DISCOUNT = "on_the_fly_discount",
}

export default PromoRuleTypeEnum;
