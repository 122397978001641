export type formatThousandSeparatorInput = number;

export type formatThousandSeparatorOutput = string;

export const formatThousandSeparator = (
  input: formatThousandSeparatorInput
): formatThousandSeparatorOutput => {
  return new Intl.NumberFormat("id-ID", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(input);
};

export default formatThousandSeparator;
