"use client";

import { useSessionQuery } from "@saas/account/data-access";
import { getProfileFromSession, SessionProfile } from "@saas/account/utils";
import { ErrorResponseData, FetchError } from "@saas/core";

export type UseSessionProfileReturnType = {
  profile?: SessionProfile | null;
  error: FetchError<ErrorResponseData> | null;
  refetch: ReturnType<typeof useSessionQuery>["refetch"];
};

export const useSessionProfile = (): UseSessionProfileReturnType => {
  const {
    data: profile,
    error,
    refetch,
  } = useSessionQuery({
    select: (data) => getProfileFromSession(data),
  });

  return { profile, error, refetch };
};

export default useSessionProfile;
