import { PromoRuleTypeEnum } from "../..";

export type GetPromoRuleLabelInput = PromoRuleTypeEnum;

export type GetPromoRuleLabelOutput = string;

export const getPromoRuleLabel = (
  input?: GetPromoRuleLabelInput
): GetPromoRuleLabelOutput => {
  switch (input) {
    case PromoRuleTypeEnum.MINIMUM_ORDER_QUANTITY:
      return "Jumlah Pembelian Barang Minimum";
    case PromoRuleTypeEnum.MINIMUM_ORDER_SALES_AMOUNT:
      return "Nominal Pembelian Minimum";
    case PromoRuleTypeEnum.MINIMUM_PRODUCT_ORDER_QUANTITY:
      return "Pembelian Produk Khusus";
    case PromoRuleTypeEnum.PAYMENT_METHOD:
      return "Promo Pembayaran";
    default:
      return "";
  }
};
export default getPromoRuleLabel;
