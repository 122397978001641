/**
 * @deprecated
 * This enum is outdated in POS. Use `CashierV2PaymentMethodEnum` instead.
 * subject of termination once `pos/split-payment` release.
 */
export enum PaymentMethodEnum {
  CASH = "cash",
  INVOICE = "invoice",
  EWALLET_GOPAY = "ewallet_gopay",
  EWALLET_OVO = "ewallet_ovo",
  EWALLET_DANA = "ewallet_dana",
  EWALLET_LINKAJA = "ewallet_linkaja",
  QRIS = "qris",
  BCA = "bca",
  MANDIRI = "mandiri",
  BNI = "bni",
  BRI = "bri",
  CREDIT_CARD = "credit_card",
  OTHERS = "others",
  EWALLET_OTHERS = "ewallet_others",
}

/**
 * @deprecated
 * This enum is outdated in POS. Use `CashierV2PaymentMethodEnum` instead.
 * subject of termination once `pos/split-payment` release.
 */
export enum TransferPaymentMethodEnum {
  BCA = "bca",
  MANDIRI = "mandiri",
  BNI = "bni",
  BRI = "bri",
}

/**
 * @deprecated
 * This enum is outdated in POS. Use `CashierV2PaymentMethodEnum` instead.
 * subject of termination once `pos/split-payment` release.
 */
export enum OtherPaymentMethodEnum {
  EWALLET_GOPAY = "ewallet_gopay",
  EWALLET_OVO = "ewallet_ovo",
  EWALLET_DANA = "ewallet_dana",
  EWALLET_LINKAJA = "ewallet_linkaja",
  QRIS = "qris",
  BCA = "bca",
  MANDIRI = "mandiri",
  BNI = "bni",
  BRI = "bri",
  CREDIT_CARD = "credit_card",
  OTHERS = "others",
  EWALLET_OTHERS = "ewallet_others",
}

export default PaymentMethodEnum;
