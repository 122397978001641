import { Money } from "@saas/shared/utils";

import { PromoLabelEnum, StorePromoRewardInterface } from "../../";

export type GetPromoRewardLabelInput = {
  label: PromoLabelEnum;
  minimumQuantity?: number;
  nominal?: Money;
  maximum?: Money;
  reward: StorePromoRewardInterface;
};

export type GetPromoRewardLabelOutput = string;

export const getPromoRewardLabel = ({
  label,
  minimumQuantity,
  nominal,
  maximum,
  reward,
}: GetPromoRewardLabelInput): GetPromoRewardLabelOutput => {
  const percentage = reward.percentage;
  const quantity = reward.quantity;
  const itemName = reward.item?.name;

  if (label === PromoLabelEnum.PRODUCT_BUY_N_GET_M) {
    return `Buy N Get ${quantity}, Min. Pembelian: ${minimumQuantity} barang`;
  }

  if (itemName && label === PromoLabelEnum.PRODUCT_BUNDLING) {
    return `FREE Bundling (Gratis Produk): "${itemName}"`;
  }

  if (nominal) {
    return `Diskon: ${nominal.currency} ${nominal.value}`;
  }

  if (percentage && maximum && label !== PromoLabelEnum.PRODUCT_DISCOUNT) {
    return `Diskon: ${percentage}%, Maks: ${maximum.currency} ${maximum.value}`;
  }

  if (percentage) {
    return `Diskon: ${percentage}%`;
  }

  return "";
};

export default getPromoRewardLabel;
