"use client";

import { classNames } from "@saas/shared/utils";

export interface DividerProps {
  type?: "solid" | "dashed";
  orientation: "vertical" | "horizontal";
  className?: string;
}

export const Divider = ({
  type = "solid",
  orientation,
  className,
}: DividerProps) => {
  const orientationClass = {
    vertical: "block w-[1px] h-full",
    horizontal: "w-full block h-[1px]",
  };

  const typeClass = {
    solid: "bg-neutral-N200",
    dashed:
      orientation === "vertical"
        ? "bg-dashed-vertical bg-[length:1px_10px]"
        : "bg-dashed-horizontal bg-[length:10px_1px]",
  };

  return (
    <span className={"relative"}>
      <span
        className={classNames(
          orientationClass[orientation],
          typeClass[type],
          className
        )}
      ></span>
    </span>
  );
};

export default Divider;
