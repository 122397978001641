// eslint-disable-next-line @nx/enforce-module-boundaries
import { CashierOrderInterface } from "@saas/store/utils";

import {
  CartItemInterface,
  CashierOfflineOrderInterface,
  OrderProductInterface,
} from "..";

export type MapOfflineOrderToCashierOrderInput = CashierOfflineOrderInterface;

export type MapOfflineOrderToCashierOrderOutput = CashierOrderInterface;

const mapToCartItem = (product: OrderProductInterface): CartItemInterface => {
  const variant = product.variants[0];

  return {
    masterProductVariantId: variant.masterProductVariantId,
    name: product.name,
    sku: variant.sku,
    image: variant.imageUrl,
    price: variant.originalPrice ?? 0,
    originalPrice: variant.originalPrice ?? 0,
    stock: variant.quantity,
    quantity: variant.quantity,
    productName: variant.name,
    productId: product.productId,
    discountedPrice: variant.discountedPrice,
    discountNotes: variant.discountNotes,
  };
};

export const mapOfflineOrderToCashierOrder = (
  order: MapOfflineOrderToCashierOrderInput
): MapOfflineOrderToCashierOrderOutput => {
  return {
    uuid: order.uuid || "",
    notes: order.notes ?? "",
    payment: order.payment,
    payments: order.payments,
    orderModifier: order.orderModifier,
    createdAt: new Date(order.createdAt),
    recipient: order.recipient,
    sessionId: order.sessionId || "",
    salespersonId: order.salespersonId,
    appliedPromoIds: [],
    manualPromoIds: [],
    voucherCodes: [],
    onTheFlyDiscounts: [],
    total: order.total ?? 0,
    totalPaid: 0,
    productVariants: order.products.map(mapToCartItem),
    useDefaultRecipient: order.useDefaultRecipient ?? false,
    isCreatedOnOfflineDevice: order.isCreatedOnOfflineDevice ?? false,
  };
};

export default mapOfflineOrderToCashierOrder;
