import {
  expiryOptions,
  getPointExpirationDate,
  RewardExpiryEnum,
  StorePromoInterface,
  StorePromoModel,
} from "../../";

import { findIndex } from "lodash-es";

export type GetPromoExpirationInput = StorePromoModel["expiration"];

export type GetPromoExpirationOutput =
  | StorePromoInterface["expiration"]
  | undefined;

export const getPromoExpiration = (
  expiration: GetPromoExpirationInput
): GetPromoExpirationOutput => {
  const rewardExpirationLabelMap = {
    [RewardExpiryEnum.DURATION_TRANSACTION_TIME]:
      "Durasi Berdasarkan Waktu Transaksi",
    [RewardExpiryEnum.REPETITION_TIME_ANNUALLY]: "Pengulangan Waktu Tahunan",
    [RewardExpiryEnum.DURATION_REGISTRATION_TIME]:
      "Durasi Berdasarkan Waktu Terdaftar",
    [RewardExpiryEnum.EXACT_DATE]: "",
    [RewardExpiryEnum.REPETITION_TIME_MONTHLY]: "",
  };

  if (expiration?.value) {
    const expiryOptionsData = expiryOptions({});
    const expiryValue = parseInt(expiration?.value ?? "0", 10);
    const expiryPeriod = expiryOptionsData.expiryPeriod
      .filter((period) => expiryValue % period.value === 0)
      .slice(-1)[0];
    const expiryType = expiration.type;

    const isAnnualExpiry =
      expiration?.type === RewardExpiryEnum.REPETITION_TIME_ANNUALLY;

    const expiry = expiration?.value?.split("/") || ["01", "01"];
    const expiryDay = expiry[0];
    const expiryMonth = expiry[1];

    const monthIndex = findIndex(expiryOptionsData.expiryMonth, {
      value: expiryMonth,
    });

    const month = expiryOptionsData.expiryMonth[monthIndex];

    return {
      type: expiration.type,
      typeLabel: rewardExpirationLabelMap[expiryType],
      ...(isAnnualExpiry
        ? {
            date: {
              day: expiryDay,
              month: month.label,
              expiredDate: expiration.value
                ? getPointExpirationDate({
                    date: expiryDay,
                    month: month.value,
                  })
                : undefined,
            },
          }
        : {
            transaction: {
              duration: expiryValue / (expiryPeriod.value ?? 1),
              period: {
                label: expiryPeriod.label ?? "",
                value: expiryPeriod.value,
              },
            },
          }),
    };
  }
};

export default getPromoExpiration;
