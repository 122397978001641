import { ChannelEnum } from "@saas/shared/utils";

import { StorePromoChannelInterface } from "../../";

export type GetStorePromoAvailableChannelInput =
  ReadonlyArray<StorePromoChannelInterface>;

export type GetStorePromoAvailableChannelOutput = {
  availableChannel: ReadonlyArray<string>;
  availableCatalog: ReadonlyArray<{
    name: string;
    id: number;
  }>;
};

export const getStorePromoAvailableChannel = (
  channels: GetStorePromoAvailableChannelInput
): GetStorePromoAvailableChannelOutput => {
  const isOflineChannel =
    channels.findIndex(({ channel }) => channel === ChannelEnum.OFFLINE) > -1;
  const availableCatalog = channels
    .filter(({ channel }) => channel === ChannelEnum.CATALOG)
    .map(({ name, id }) => ({ name, id }));
  const availableChannel = [];

  if (isOflineChannel) {
    availableChannel.push("Melaka Kasir");
  }

  const catalogLength = availableCatalog.length;

  if (catalogLength) {
    if (catalogLength > 3) {
      availableChannel.push(`Melaka Katalog (${catalogLength} Katalog)`);
    } else {
      availableChannel.push("Melaka Katalog:");
    }
  }

  return {
    availableChannel,
    availableCatalog,
  };
};

export default getStorePromoAvailableChannel;
