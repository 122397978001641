"use client";

import type { ReactNode } from "react";

import {
  CheckCircleIcon,
  CloseIcon,
  ErrorIcon,
  InfoIcon,
  SVG,
  WarningIcon,
} from "@saas/shared/icon";
import { classNames } from "@saas/shared/utils";

import { BaseButton, Typography } from "..";

enum NotificationVariant {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
}

export interface NotificationBarBaseProps {
  testid?: string;
  variant?: `${NotificationVariant}`;
  title?: string;
  message: ReactNode | string[];
  action?: undefined;
  onClose?: () => void;
  icon?: SVG;
}

export interface NotificationBarWithActionProps {
  testid?: string;
  variant?: `${NotificationVariant}`;
  title?: string;
  message: ReactNode | string[];
  action: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    testid?: string;
  };
  onClose?: () => void;
  icon?: SVG;
}

export type NotificationBarProps =
  | NotificationBarBaseProps
  | NotificationBarWithActionProps;

export const NotificationBar = ({
  testid,
  variant,
  title,
  message,
  action,
  onClose,
  icon: Icon = InfoIcon,
}: NotificationBarProps) => {
  const icon: { [key: string]: SVG } = {
    [NotificationVariant.INFO]: InfoIcon,
    [NotificationVariant.SUCCESS]: CheckCircleIcon,
    [NotificationVariant.WARNING]: WarningIcon,
    [NotificationVariant.ERROR]: ErrorIcon,
  };

  const iconClassName = {
    [NotificationVariant.INFO]: "text-blue-B700",
    [NotificationVariant.SUCCESS]: "text-green-G700",
    [NotificationVariant.WARNING]: "text-orange-O700",
    [NotificationVariant.ERROR]: "text-red-R700",
  };

  const bgClassName = {
    [NotificationVariant.INFO]: "bg-blue-B200",
    [NotificationVariant.SUCCESS]: "bg-green-G200",
    [NotificationVariant.WARNING]: "bg-orange-O200",
    [NotificationVariant.ERROR]: "bg-red-R200",
  };

  const IconComponent = variant ? icon[variant] : Icon;

  const displayMessage = (msg: ReactNode) => (
    <Typography
      as={"p"}
      type={"body-b2"}
      className={"text-he"}
      data-testid={testid ? `${testid}__content` : ""}
    >
      {msg}
    </Typography>
  );

  return (
    <div
      className={classNames(
        "flex gap-4 rounded-lg p-3",
        variant ? bgClassName[variant] : "bg-blue-B200"
      )}
      data-testid={testid}
    >
      <div className={"flex w-full gap-4"}>
        <div className={"flex w-full gap-2"}>
          <div className={"flex-shrink-0"}>
            <IconComponent
              className={classNames(
                "h-6 w-6",
                variant ? iconClassName[variant] : "text-blue-B700"
              )}
              data-testid={testid ? `${testid}__icon` : ""}
            />
          </div>

          <div
            className={
              "flex w-full flex-col items-center gap-2 md:flex-row md:gap-4"
            }
          >
            <div className={"flex w-full flex-col gap-1"}>
              {title ? (
                <Typography
                  as={"h4"}
                  type={"title-small"}
                  className={"text-he"}
                  data-testid={testid ? `${testid}__title` : ""}
                >
                  {title}
                </Typography>
              ) : null}

              {Array.isArray(message) ? (
                <ul className={"list-disc pl-5"}>
                  {message.map((m, index) => (
                    <li key={index}>{displayMessage(m)}</li>
                  ))}
                </ul>
              ) : (
                displayMessage(message)
              )}
            </div>

            {action ? (
              <span className={"flex-shrink-0 md:my-auto"}>
                <BaseButton
                  variant={"text"}
                  className={classNames("px-0 uppercase", !title && "py-0")}
                  disabled={action.disabled ?? false}
                  onClick={() => action.onClick()}
                  data-testid={action.testid}
                >
                  {action.label}
                </BaseButton>
              </span>
            ) : null}
          </div>
        </div>
      </div>

      {onClose ? (
        <button
          className={"text-le hover:text-le/80 self-start"}
          onClick={onClose}
        >
          <span className={"sr-only"}>Close</span>
          <CloseIcon className={"h-6 w-6"} />
        </button>
      ) : null}
    </div>
  );
};

export default NotificationBar;
