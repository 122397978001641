export type GenerateStorageKeyInput = {
  /**
   * Key of the storage
   */
  key: string;
  /**
   * User id to avoid conflicts
   * @default ""
   */
  userId?: string;
  /**
   * Version of the storage key to avoid conflicts
   * when the structure of the storage key changes
   * @default 1
   */
  version?: number;
};

export const generateStorageKey = ({
  key,
  userId = "",
  version = 1,
}: GenerateStorageKeyInput) => {
  const storageKey = `${key}-${userId}-v${version}`;

  return storageKey;
};

export default generateStorageKey;
