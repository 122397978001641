"use client";

import { ReactNode } from "react";

import { SVG } from "@saas/shared/icon";
import { classNames } from "@saas/shared/utils";

import { Typography } from "..";

export interface MenuItemProps {
  icon?: SVG;
  active?: boolean;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  enableFeatureFlag?: boolean;
  hasSubmenu?: boolean;
}

export const MenuItem = ({
  active,
  className,
  children,
  disabled = false,
  icon: Icon,
  enableFeatureFlag,
  hasSubmenu,
}: MenuItemProps) => {
  const currentActive = active
    ? "bg-blue-B100"
    : disabled
    ? "bg-white"
    : "group-hover:bg-blue-B100 bg-white";
  const newActive =
    active && !hasSubmenu
      ? "bg-neutral-N100"
      : "group-hover:bg-neutral-N100 bg-white";

  const activeBg = enableFeatureFlag ? newActive : currentActive;

  return (
    <span
      className={classNames(
        "flex min-h-[2.5rem] items-center gap-4 rounded",
        activeBg,
        className
      )}
    >
      {Icon ? (
        <Icon
          className={classNames(
            "inline-flex flex-shrink-0",
            active && enableFeatureFlag
              ? "text-blue-B700"
              : disabled
              ? "text-disabled"
              : "text-le"
          )}
        />
      ) : null}
      <Typography
        type={
          active && enableFeatureFlag && !hasSubmenu
            ? "body-b2-semibold"
            : "body-b2"
        }
        className={classNames(
          "w-full text-left",
          disabled ? "text-disabled" : "text-he",
          active && enableFeatureFlag && "font-semibold"
        )}
      >
        {children}
      </Typography>
    </span>
  );
};

export default MenuItem;
