"use client";

import { ComponentPropsWithoutRef, forwardRef, ReactNode } from "react";

import { SpinnerIcon, SVG } from "@saas/shared/icon";
import { classNames } from "@saas/shared/utils";

import { Typography } from "..";

const Variant = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  TEXT: "text",
  ELEVATED: "elevated",
  NEGATIVE: "negative",
  INVERT: "invert",
} as const;

const Size = {
  MINI: "mini",
  SMALL: "small",
  BIG: "big",
} as const;

const Fill = {
  SQUARE: "square",
  ROUNDED: "rounded",
} as const;

export interface BaseButtonProps extends ComponentPropsWithoutRef<"button"> {
  /**
   * The HTML element to render
   * @default "button"
   */
  as?: "button" | "span";
  /**
   * The variant of the button
   */
  variant: (typeof Variant)[keyof typeof Variant];
  /**
   * The fill type of the button
   * @default "square"
   */
  fill?: (typeof Fill)[keyof typeof Fill];
  /**
   * The size of the button
   * @default "big"
   */
  size?: (typeof Size)[keyof typeof Size];
  /**
   * The icon to display
   */
  icon?: SVG;
  /**
   * The content of the button
   */
  children: ReactNode;
  /**
   * Loading state of the button
   * @default false
   */
  isLoading?: boolean;
  testid?: string;
}

export const BaseButton = forwardRef<HTMLButtonElement, BaseButtonProps>(
  (
    {
      as: Element = "button",
      variant,
      size = Size.BIG,
      fill = Fill.SQUARE,
      icon: Icon,
      children,
      className,
      testid,
      isLoading = false,
      disabled,
      ...props
    }: BaseButtonProps,
    ref
  ) => {
    const variantClass = {
      [Variant.PRIMARY]:
        "text-disabled bg-neutral-N200 enabled:text-invert enabled:bg-blue-B700 enabled:hover:bg-blue-B600 enabled:focus:bg-blue-B600",
      [Variant.SECONDARY]:
        "text-disabled bg-neutral-N200 enabled:text-button enabled:bg-blue-B200 enabled:hover:bg-blue-B100 enabled:focus:bg-blue-B100",
      [Variant.TERTIARY]:
        "text-disabled enabled:text-he border-neutral-N300 enabled:hover:text-le enabled:focus:text-button border",
      [Variant.TEXT]:
        "text-disabled enabled:text-button enabled:hover:text-blue-B600",
      [Variant.ELEVATED]:
        "text-disabled bg-neutral-N200 enabled:text-button enabled:bg-blue-B200 enabled:hover:bg-blue-B100 enabled:focus:bg-blue-B100 shadow-elevation-1",
      [Variant.NEGATIVE]:
        "text-disabled bg-neutral-N200 enabled:text-invert enabled:bg-red-R500 enabled:hover:bg-red-R400 enabled:focus:bg-red-R400",
      [Variant.INVERT]:
        "text-disabled bg-neutral-N200 enabled:text-button enabled:bg-neutral-N0 enabled:hover:text-blue-B600 enabled:focus:bg-transparent",
    };

    const iconClass = {
      [Variant.PRIMARY]: "text-disabled group-enabled:text-invert",
      [Variant.SECONDARY]:
        "text-disabled group-enabled:text-blue-B700 group-enabled:group-hover:text-blue-B600",
      [Variant.TERTIARY]: "text-disabled group-enabled:text-le",
      [Variant.TEXT]:
        "text-disabled group-enabled:text-blue-B700 group-enabled:group-hover:text-blue-B600",
      [Variant.ELEVATED]:
        "text-disabled group-enabled:text-blue-B700 group-enabled:group-hover:text-blue-B600",
      [Variant.NEGATIVE]: "text-disabled group-enabled:text-invert",
      [Variant.INVERT]: "text-disabled group-enabled:text-button",
    };

    const sizeClass = {
      [Size.MINI]: variant === Variant.TEXT ? "py-1 px-2" : "py-1 px-3",
      [Size.SMALL]: variant === Variant.TEXT ? "py-2 px-3" : "py-2 px-6",
      [Size.BIG]: variant === Variant.TEXT ? "p-3" : "py-3 px-6",
    };

    const fillClass = {
      [Fill.SQUARE]: size === Size.MINI ? "rounded-lg" : "rounded-xl",
      [Fill.ROUNDED]: "rounded-full",
    };

    return (
      <Element
        type={props.type ?? "button"}
        className={classNames(
          "group relative inline-flex items-center justify-center gap-2 enabled:focus:outline-none",
          variantClass[variant],
          sizeClass[size],
          fillClass[fill],
          Icon && variant !== Variant.TEXT && "pl-4",
          (disabled || isLoading) && "cursor-not-allowed",
          className
        )}
        ref={ref}
        data-testid={testid}
        disabled={disabled || isLoading}
        {...props}
      >
        {Icon ? (
          <Icon
            className={classNames(
              size === "big" ? "h-6 w-6" : "h-4 w-4",
              iconClass[variant]
            )}
          />
        ) : null}

        <Typography
          type={size === Size.MINI ? "button-small" : "button-large"}
          className={classNames(isLoading && "invisible")}
        >
          {children}
        </Typography>

        {isLoading ? (
          <span className={classNames("absolute flex")}>
            <SpinnerIcon
              className={classNames(
                "text-neutral-N0 animate-spin self-center",
                size === "big" ? "h-6 w-6" : "h-4 w-4"
              )}
            />
          </span>
        ) : null}
      </Element>
    );
  }
);

BaseButton.displayName = "BaseButton";

export default BaseButton;
