import dayjs from "dayjs";

export type GetPointExpirationDateInput = {
  date: string | number;
  month: string | number;
};

export type GetPointExpirationDateOutput = string;

const resultDateFormat = "D MMMM YYYY";

export const getPointExpirationDate = (
  input: GetPointExpirationDateInput
): string => {
  const [date, month] = Object.values(input).map((value) =>
    typeof value === "number" ? value : parseInt(value, 10)
  );
  const expirationDateMonth = dayjs()
    .set("dates", date)
    .set("months", month - 1);

  return dayjs().add(1, "day").isAfter(expirationDateMonth, "day")
    ? expirationDateMonth.add(1, "year").format(resultDateFormat)
    : expirationDateMonth.format(resultDateFormat);
};

export default getPointExpirationDate;
