"use client";

import { useSessionProfile } from "@saas/account/feature";
import { queryClient } from "@saas/core";
import { LOCALSTORAGE_DRAFT_ORDERS_KEY } from "@saas/order/utils";
import { generateStorageKey, Money } from "@saas/shared/utils";
import { type DraftedCashierInterface } from "@saas/store/utils";

import { useQuery } from "@tanstack/react-query";

export type UseDraftOrderStorageQueryReturnType = {
  data: Array<DraftedCashierInterface>;
  isSuccess: boolean;
  appendData: (data: DraftedCashierInterface) => void;
  removeData: (data: DraftedCashierInterface) => void;
  clearData: () => void;
};

export const useDraftOrderStorageQuery =
  (): UseDraftOrderStorageQueryReturnType => {
    const { profile } = useSessionProfile();

    const draftStorageKey = generateStorageKey({
      key: LOCALSTORAGE_DRAFT_ORDERS_KEY,
      userId: profile?.id,
      version: 1,
    });

    const { data: draftOrders, isSuccess } = useQuery([draftStorageKey], () => {
      const storedDraftOrder = localStorage.getItem(draftStorageKey);
      const parsedDraftOrder: Array<DraftedCashierInterface> = storedDraftOrder
        ? JSON.parse(storedDraftOrder)
        : [];

      const data = parsedDraftOrder.map((draftOrder) => ({
        ...draftOrder,
        cart: {
          ...draftOrder.cart,
          totalDiscountAmount: new Money(
            draftOrder.cart.totalDiscountAmount?.data.integer,
            {
              rounded: true,
            }
          ),
          totalOriginalAmount: new Money(
            draftOrder.cart.totalOriginalAmount?.data.integer,
            {
              rounded: true,
            }
          ),
        },
      }));

      return data;
    });

    const appendData = (data: DraftedCashierInterface) => {
      const nameLowerCase = data.name.toLowerCase();

      const newData = [
        ...(draftOrders || []).filter(
          (draftOrder) => draftOrder.name.toLowerCase() !== nameLowerCase
        ),
        data,
      ];

      localStorage.setItem(draftStorageKey, JSON.stringify(newData));
      queryClient.setQueryData([draftStorageKey], newData);
    };

    const removeData = (data: DraftedCashierInterface) => {
      const nameLowerCase = data.name.toLowerCase();

      const newData = (draftOrders || []).filter(
        (draftOrder) => draftOrder.name.toLowerCase() !== nameLowerCase
      );

      localStorage.setItem(draftStorageKey, JSON.stringify(newData));
      queryClient.setQueryData([draftStorageKey], newData);
    };

    const clearData = () => {
      localStorage.removeItem(draftStorageKey);
      queryClient.invalidateQueries([draftStorageKey]);
    };

    return {
      data: draftOrders || [],
      isSuccess,
      appendData,
      removeData,
      clearData,
    };
  };

export default useDraftOrderStorageQuery;
