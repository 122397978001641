"use client";

import {
  GetMarketplaceConnectionListOutput,
  useGetMarketplaceConnectionsQueryV2,
} from "@saas/marketplace-connection/data-access";
import {
  MarketplaceConnectionInterface,
  MarketplaceListStatusEnum,
  MarketplaceTypeEnum,
} from "@saas/marketplace-connection/utils";
import { QueryOptionsInterface } from "@saas/shared/utils";

export const useConnectedChatMarketplaceConnectionsQuery = (
  options?: QueryOptionsInterface<
    GetMarketplaceConnectionListOutput,
    Array<MarketplaceConnectionInterface>
  >
) => {
  return useGetMarketplaceConnectionsQueryV2(
    {
      select: (data) => {
        return data.data.filter(
          (marketplaceConnection) =>
            marketplaceConnection.connectionType === "chat" &&
            (marketplaceConnection.status ===
              MarketplaceListStatusEnum.CONNECTED ||
              marketplaceConnection.status ===
                MarketplaceListStatusEnum.EXPIRED)
        );
      },
      ...options,
    },
    { connectionType: [MarketplaceTypeEnum.CHAT] }
  );
};

export default useConnectedChatMarketplaceConnectionsQuery;
