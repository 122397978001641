import { PaginationInterface } from "@saas/shared/utils";

export type GeneratePaginationInput = {
  totalItems: number;
  currentPage: number;
  perPage: number;
};

export type GeneratePaginationOutput = PaginationInterface;

export const generatePagination = ({
  totalItems,
  currentPage,
  perPage,
}: GeneratePaginationInput): GeneratePaginationOutput => {
  const toItem = currentPage * perPage;
  const itemIndex = toItem - perPage;
  const pagination: PaginationInterface = {
    totalItems,
    perPage,
    currentPage,
    totalPage: Math.ceil(totalItems / perPage),
    toItem: toItem,
    fromItem: itemIndex + 1,
    sort: "",
    nextUrl: "",
    previousUrl: "",
  };

  return pagination;
};

export default generatePagination;
