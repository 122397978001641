import { CartItemInterface } from "../../";

export type GetOfflineTotalPriceInput = {
  productVariants: ReadonlyArray<CartItemInterface>;
  sellerVoucherAmount: number;
  discountProductsAmount: number;
};

export type GetOfflineTotalPriceOutput = number;

export const getOfflineTotalPrice = ({
  productVariants,
  sellerVoucherAmount,
  discountProductsAmount,
}: GetOfflineTotalPriceInput): GetOfflineTotalPriceOutput => {
  const totalPrice =
    productVariants.reduce((total, data) => {
      return total + data.price * data.quantity;
    }, 0) -
    sellerVoucherAmount -
    discountProductsAmount;

  return Math.max(totalPrice, 0);
};

export default getOfflineTotalPrice;
