import { PromoLabelEnum } from "../../";

export type GetStorePromoTierTypeInput = {
  rewardPercentage?: number;
  label: PromoLabelEnum;
};

export type GetStorePromoTierTypeOutput = string | undefined;

export const getStorePromoTierType = ({
  rewardPercentage,
  label,
}: GetStorePromoTierTypeInput): GetStorePromoTierTypeOutput => {
  if (
    label === PromoLabelEnum.PRODUCT_BUY_N_GET_M ||
    label === PromoLabelEnum.PRODUCT_BUNDLING
  ) {
    return;
  }

  return rewardPercentage === 100 ? "Diskon Nominal" : "Diskon Persentase";
};

export default getStorePromoTierType;
