import { PromoPaymentMethodEnum } from "../../";

export type GetPromoPaymentMethodLabelInput = PromoPaymentMethodEnum;

export type GetPromoPaymentMethodLabelOutput = string;

export const getPromoPaymentMethodLabel = (
  paymentMethod: GetPromoPaymentMethodLabelInput
): GetPromoPaymentMethodLabelOutput => {
  const paymentMethodLabel = {
    [PromoPaymentMethodEnum.CASH]: "Tunai",
    [PromoPaymentMethodEnum.INVOICE]: "Tempo",
    [PromoPaymentMethodEnum.TRANSFER]: "Bank Transfer",
  };

  return paymentMethodLabel[paymentMethod];
};

export default getPromoPaymentMethodLabel;
