"use client";

import { useMemo } from "react";

import useWS, { Options, ReadyState } from "react-use-websocket";

const connectionStatuses = {
  [ReadyState.CONNECTING]: "Connecting",
  [ReadyState.OPEN]: "Open",
  [ReadyState.CLOSING]: "Closing",
  [ReadyState.CLOSED]: "Closed",
  [ReadyState.UNINSTANTIATED]: "Uninstantiated",
} as const;

type ConnectionStatus = keyof typeof connectionStatuses;
export type ConnectionStatusValue =
  (typeof connectionStatuses)[ConnectionStatus];

export type UseWebSocketProps = {
  connect: boolean;
  options: Options;
  subscriptionUrl: string;
};

export type UseWebSocketReturnType<MessageType = unknown> = {
  canSendMessage: boolean;
  connectionStatus: ConnectionStatusValue;
  /**
   * This will be typed properly once we have started integrating with BE
   */
  lastMessage: MessageType;
  sendMessage: <T = MessageType>(jsonMessage: T, keep?: boolean) => void;
};

export const useWebSocket = <MessageType = unknown>({
  connect,
  options,
  subscriptionUrl,
}: UseWebSocketProps): UseWebSocketReturnType<MessageType> => {
  const { lastJsonMessage, readyState, sendJsonMessage } = useWS<MessageType>(
    subscriptionUrl,
    {
      ...options,
      fromSocketIO: false,
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    },
    connect
  );

  const connectionStatus = connectionStatuses[readyState];

  const canSendMessage = useMemo(() => {
    return connectionStatus === "Open";
  }, [connectionStatus]);

  return {
    canSendMessage,
    connectionStatus,
    lastMessage: lastJsonMessage,
    sendMessage: sendJsonMessage,
  };
};

export default useWebSocket;
