export type FormatLocaleCurrencyInput = number;

export type FormatLocaleCurrencyOptions = {
  locale?: string;
  currency?: string;
  rounded?: boolean;
};

export type FormatLocaleCurrencyOutput = string;

export const formatLocaleCurrency = (
  input: FormatLocaleCurrencyInput,
  options?: FormatLocaleCurrencyOptions
): FormatLocaleCurrencyOutput => {
  return new Intl.NumberFormat(options?.locale ?? "id-ID", {
    style: "currency",
    currency: options?.currency ?? "IDR",
    currencyDisplay: "symbol",
    maximumFractionDigits: options?.rounded ? 0 : 2,
  }).format(input);
};

export default formatLocaleCurrency;
