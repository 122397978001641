"use client";

import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useMemo } from "react";

import { env } from "@saas/config/shared";
import { SVG } from "@saas/shared/icon";
import { Divider, Logo, MenuItem } from "@saas/shared/ui";
import { classNames, NewMenuEnum } from "@saas/shared/utils";

export interface Navigation {
  label: string;
  href?: string;
  target?: string;
  icon?: SVG;
  testid?: string;
  enabled?: boolean;
  children?: Navigation[];
  gap?: boolean;
  keyIndicator?: NewMenuEnum;
  sticky?: boolean;
  className?: string;
  isActive?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export interface PosNavigationProps {
  navigation: ReadonlyArray<Navigation>;
  onNavigate?: () => void;
}

export const PosNavigation = ({
  navigation,
  onNavigate: handleNavigate,
}: PosNavigationProps) => {
  const { asPath } = useRouter();

  const withActiveNavigation = useMemo(() => {
    return navigation.map((item) => {
      const href = item.href ?? "";
      const isExternal = href?.startsWith("http");
      const navigationUrl = new URL(isExternal ? href : env.APP_URL + href);
      const currentUrl = new URL(env.APP_URL + asPath);
      const navigationPath =
        navigationUrl.pathname.split("/")[1] + navigationUrl.hash;
      const pathComponents = currentUrl.pathname.split("/");
      const basePath = pathComponents.slice(0, 2).join("/") + currentUrl.hash;

      const isActive = isExternal
        ? false
        : !item.href
        ? false
        : navigationPath
        ? basePath.substring(1) === navigationPath
        : basePath === "/";

      return {
        ...item,
        isActive,
      };
    });
  }, [asPath, navigation]);

  const renderMenuItem = (item: Navigation & { isActive: boolean }) => (
    <MenuItem
      className={"gap-[10px] py-2 pr-2 pl-6"}
      icon={item.icon}
      active={item.isActive}
      enableFeatureFlag={true}
      disabled={item.disabled}
    >
      <span className={"inline-flex items-center gap-2"}>{item.label}</span>
    </MenuItem>
  );

  return (
    <aside
      className={
        "bg-neutral-N0 border-neutral-N200 z-10 min-h-screen border-r md:fixed md:inset-y-0 md:flex md:w-[248px] md:flex-col"
      }
    >
      <div className={"flex flex-grow flex-col overflow-y-auto"}>
        <div className={"m-6 flex flex-shrink-0 items-center"}>
          <Logo variant={"pos"} />
        </div>
        <nav className={classNames("mr-2 flex flex-1 flex-col gap-1")}>
          {withActiveNavigation.map((item) => {
            return item.enabled ? (
              <Fragment key={item.label}>
                {item.gap ? (
                  <div className={"pl-6"}>
                    <Divider orientation={"horizontal"} />
                  </div>
                ) : null}

                {item.href ? (
                  <Link
                    shallow={true}
                    href={item.href}
                    prefetch={true}
                    aria-disabled={item.disabled}
                    className={classNames(
                      "group block w-full cursor-pointer overflow-hidden rounded-r-xl",
                      item.sticky && "absolute bottom-0 z-10",
                      item.className,
                      item.disabled && "pointer-events-none cursor-not-allowed"
                    )}
                    target={item.target}
                    data-testid={item.testid}
                    data-id={item.testid}
                    onClick={handleNavigate}
                  >
                    {renderMenuItem(item)}
                  </Link>
                ) : (
                  <button
                    className={classNames(
                      "group block w-full cursor-pointer overflow-hidden rounded-r-xl",
                      item.sticky && "absolute bottom-0 z-10",
                      item.className
                    )}
                    data-testid={item.testid}
                    data-id={item.testid}
                    onClick={() => item.onClick?.()}
                    disabled={item.disabled}
                  >
                    {renderMenuItem(item)}
                  </button>
                )}
              </Fragment>
            ) : null;
          })}
        </nav>
      </div>
    </aside>
  );
};

export default PosNavigation;
