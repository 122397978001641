import { Money } from "@saas/shared/utils";

export type GetRoundedTotalInput = {
  total: number;
  isRounded: boolean;
};

export type GetRoundedTotalOutput = {
  total: Money;
  roundedDifference: number;
};

export const getRoundedTotal = ({
  total,
  isRounded,
}: GetRoundedTotalInput): GetRoundedTotalOutput => {
  const totalRounded = Math.ceil(total / 100) * 100;

  const roundedDifference = totalRounded - total;

  return {
    total: new Money(isRounded ? totalRounded : total, { rounded: true }),
    roundedDifference: isRounded ? roundedDifference : 0,
  };
};

export default getRoundedTotal;
