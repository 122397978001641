import { DropdownOption } from "@saas/shared/ui";

import { RewardExpiryEnum } from "../..";

export type RedeemableOptionsInput = {
  testid?: string;
  options?: {
    flags: {
      promotionNominalLoyaltyPointsFlag: boolean;
    };
  };
};

export type RedeemableOptionsOutput = {
  rewardMethod: ReadonlyArray<DropdownOption<string>>;
  expiryType: ReadonlyArray<DropdownOption<string>>;
  expiryMonth: ReadonlyArray<DropdownOption<string>>;
  expiryDay: ReadonlyArray<DropdownOption<string>>;
  rounding: ReadonlyArray<DropdownOption<string>>;
  expiryPeriod: ReadonlyArray<DropdownOption<number>>;
  dateLimit: Record<string, number>;
};

export type expiryOptionsOutput = {
  expiryType: ReadonlyArray<DropdownOption<string>>;
  expiryMonth: ReadonlyArray<DropdownOption<string>>;
  expiryDay: ReadonlyArray<DropdownOption<string>>;

  expiryPeriod: ReadonlyArray<DropdownOption<number>>;
  dateLimit: Record<string, number>;
};

export const rewardMethodOptions = ({
  testid,
  options,
}: RedeemableOptionsInput): ReadonlyArray<DropdownOption<string>> => {
  const { promotionNominalLoyaltyPointsFlag } = options?.flags || {};

  return [
    {
      label: "Persentase",
      value: "percentage",
      disabled: false,
      testid: testid + "__option__reward-method-percentage",
    },
    {
      label: promotionNominalLoyaltyPointsFlag
        ? "Nominal"
        : "Nominal (Akan Datang)",
      value: "nominal",
      disabled: !promotionNominalLoyaltyPointsFlag,
      testid: testid + "__option__reward-method-nominal",
    },
  ];
};

export const roundingOptions = ({
  testid,
}: RedeemableOptionsInput): ReadonlyArray<DropdownOption<string>> => {
  return [
    {
      label: "x00",
      value: "x00",
      disabled: false,
      testid: testid + "__option__rounding-option-x00",
    },
    {
      label: "x000",
      value: "x000",
      disabled: false,
      testid: testid + "__option__rounding-option-x000",
    },
    {
      label: "x0.000",
      value: "x0.000",
      disabled: false,
      testid: testid + "__option__rounding-option-x0.000",
    },
    {
      label: "x00.000",
      value: "x00.000",
      disabled: false,
      testid: testid + "__option__rounding-option-x00.000",
    },
  ];
};

export const expiryOptions = ({
  testid,
}: RedeemableOptionsInput): expiryOptionsOutput => {
  const expiryType = [
    {
      label: "Pengulangan Waktu Tahunan",
      value: RewardExpiryEnum.REPETITION_TIME_ANNUALLY,
      disabled: false,
      testid: testid + "__option__expiry-type-repetition-time-anually",
    },
    {
      label: "Durasi Berdasarkan Waktu Transaksi",
      value: RewardExpiryEnum.DURATION_TRANSACTION_TIME,
      disabled: false,
      testid: testid + "__option__expiry-type-duration-transaction-time",
    },
  ];

  const expiryMonth = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ].map((month, index) => {
    return {
      label: month,
      value: `${index + 1}`.padStart(2, "0"),
      disabled: false,
      testid: testid + "__option__expiry-month-" + month,
    };
  });

  const expiryDay = Array.from({ length: 31 }, (_, index) => ({
    label: `${index + 1}`,
    value: `${index + 1}`.padStart(2, "0"),
    disabled: false,
    testid: testid + `__option__expiry-day-${index + 1}`,
  }));

  const expiryPeriod = [
    {
      label: "Harian",
      value: 1,
      disabled: false,
      testid: testid + "__option__expiry-type-period-harian",
    },
    {
      label: "Mingguan",
      value: 7,
      disabled: false,
      testid: testid + "__option__expiry-type-period-mingguan",
    },
    {
      label: "Bulanan",
      value: 30,
      disabled: false,
      testid: testid + "__option__expiry-type-period-bulanan",
    },
  ];

  const dateLimit = {
    januari: 31,
    februari: 28,
    maret: 31,
    april: 30,
    mei: 31,
    juni: 30,
    juli: 31,
    agustus: 31,
    september: 30,
    oktober: 31,
    november: 30,
    desember: 31,
  };

  return {
    expiryType,
    expiryMonth,
    expiryDay,
    expiryPeriod,
    dateLimit,
  };
};

export const redeemableOptions = ({
  testid,
}: RedeemableOptionsInput): RedeemableOptionsOutput => {
  const rewardMethod = rewardMethodOptions({ testid });

  const rounding = roundingOptions({ testid });

  const { expiryDay, expiryMonth, expiryPeriod, expiryType, dateLimit } =
    expiryOptions({
      testid,
    });

  return {
    rewardMethod,
    expiryType,
    expiryMonth,
    expiryDay,
    rounding,
    expiryPeriod,
    dateLimit,
  };
};

export default redeemableOptions;
