"use client";

import { useChatSocketConnection } from "../../chat/web-socket-chat-connection-provider/web-socket-chat-connection-provider";

import { useMutation } from "@tanstack/react-query";

export type UseMarkAsReadMessagePayload = {
  requestId?: string;
  chatRoomId: number;
};

export const useMarkAsReadMessage = () => {
  const { sendMessage } = useChatSocketConnection();

  return useMutation(async (payload: UseMarkAsReadMessagePayload) => {
    sendMessage({
      type: "mark_as_read",
      request_id: payload.requestId,
      chat_room_id: payload.chatRoomId,
    });

    return Promise.resolve({});
  });
};

export default useMarkAsReadMessage;
