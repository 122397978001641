"use client";

import { Fragment, ReactNode, useEffect, useRef } from "react";

import { CloseIcon } from "@saas/shared/icon";
import { classNames } from "@saas/shared/utils";

import { Transition } from "@headlessui/react";

const Size = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
} as const;

export interface ContainedModalProps {
  open: boolean;
  onClose?: (bool: boolean) => void;
  size?: (typeof Size)[keyof typeof Size];
  className?: string;
  testid?: string;
  children: ReactNode;
}

/**
 * Modal that is contained in a section of the screen
 */
export const ContainedModal = ({
  open,
  onClose,
  size = Size.SMALL,
  className,
  testid = "",
  children,
}: ContainedModalProps) => {
  const sizeClass = {
    [Size.SMALL]: "md:max-w-[400px]",
    [Size.MEDIUM]: "md:max-w-[600px]",
    [Size.LARGE]: "md:max-w-auto",
  };
  const modalRef = useRef(null);

  useEffect(() => {
    const handleScroll = (event: WheelEvent) => {
      if (open) {
        event.preventDefault();
      }
    };

    if (open && modalRef.current) {
      document.body.style.overflow = "hidden";
      document.addEventListener("wheel", handleScroll, { passive: false });
    } else {
      document.body.style.overflow = "";
      document.removeEventListener("wheel", handleScroll);
    }

    return () => {
      document.body.style.overflow = "";
      document.removeEventListener("wheel", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, modalRef.current]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <div
        ref={modalRef}
        className={
          "fixed inset-0 z-[2] max-h-screen overflow-y-auto md:absolute"
        }
      >
        <div
          className={
            "flex min-h-full items-end justify-center text-center md:items-center md:p-0"
          }
        >
          <Transition.Child
            as={Fragment}
            enter={"ease-out duration-300"}
            enterFrom={"opacity-0"}
            enterTo={"opacity-100"}
            leave={"ease-in duration-200"}
            leaveFrom={"opacity-100"}
            leaveTo={"opacity-0"}
          >
            <div
              className={
                "bg-neutral-N700 fixed inset-0 bg-opacity-40 transition-opacity"
              }
            />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter={"ease-out duration-300"}
            enterFrom={"opacity-0 translate-y-4 md:translate-y-0 md:scale-95"}
            enterTo={"opacity-100 translate-y-0 md:scale-100"}
            leave={"ease-in duration-200"}
            leaveFrom={"opacity-100 translate-y-0 md:scale-100"}
            leaveTo={"opacity-0 translate-y-4 md:translate-y-0 md:scale-95"}
          >
            <div
              className={classNames(
                "relative inline-block h-full w-full transform overflow-auto rounded-t-xl bg-white p-5 pt-4 text-left align-bottom shadow-xl transition-all md:my-8 md:h-auto md:w-full md:rounded-lg md:p-6 md:align-middle",
                sizeClass[size],
                className
              )}
            >
              {onClose ? (
                <button
                  className={"group absolute top-6 right-6"}
                  onClick={() => onClose && onClose(false)}
                  data-testid={testid + "__close"}
                >
                  <span className={"sr-only"}>Close</span>
                  <CloseIcon
                    className={
                      "text-neutral-N600 group-hover:text-neutral-N600/80 h-6 w-6"
                    }
                  />
                </button>
              ) : null}

              {children}
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  );
};

export default ContainedModal;
