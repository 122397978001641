import { createContext, useContext } from "react";

import { FlagKeys } from "@saas/flags/utils";
// TODO: Refactor Cashier into `store`
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  CartItemInterface,
  type CashierDraftFormValues,
  CashierPaymentInterface,
  OrderRecipientInterface,
  PaymentMethodEnum,
  PaymentModifierInterface,
} from "@saas/order/utils";
// TODO: Refactor Cashier into `store`
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ShopSettingsInterface, StorePromo } from "@saas/promotion/utils";
import { generateStorageKey, Money } from "@saas/shared/utils";

import {
  CashierOrderInterface,
  CashierProductInterface,
  CashierSalesPersonInterface,
  CashierSessionInterface,
  CashierSessionStateEnum,
  CashierV2PaymentInterface,
  CashierV2PaymentMethodEnum,
  getCashierPricing,
  getPromoDetailsPayload,
  OnTheFlyDiscountInterface,
} from "../../..";
import { StorePromoModel } from "./types";

import dayjs from "dayjs";
import { findIndex } from "lodash-es";
import { v4 } from "uuid";
import { assign, createMachine, InterpreterFrom, StateValueFrom } from "xstate";

export interface CreateCashierOrderResultInterface {
  order: {
    id: number;
    uuid?: string;
  };
  orderModifier?: {
    loyaltyPointsAccumulation: number;
    loyaltyPointsRedeemed: number;
  };
}

export type CashierV2MachineContext = {
  /**
   * All current cart related data
   * Will be used to save the order as draft
   */
  cart: {
    /**
     * All current order related data
     * Will be used for offline orders and order submission
     */
    order: CashierOrderInterface;
    /**
     * Total product price
     */
    totalOriginalAmount: Money;
    /**
     * Total discounted product price
     */
    totalDiscountAmount: Money;
    /**
     * Total payment balance amount
     */
    balanceAmount?: Money;
    /**
     * Total buyer loyalty points available
     */
    loyaltyPoints?: Money;
    /**
     * Total products in cart
     */
    totalProducts: number;
    /**
     * Total product variants in cart
     */
    totalVariants: number;
    /**
     * All available promos
     */
    promos: ReadonlyArray<StorePromoModel>;
    /**
     * Salesperson information
     */
    salesperson?: CashierSalesPersonInterface;
    /**
     * New recipient information
     */
    recipients: ReadonlyArray<OrderRecipientInterface>;
    /**
     * Receipt key for online orders
     */
    receiptKey?: string;
  };
  /**
   * All offline orders that need to be submitted
   */
  offlineOrders: ReadonlyArray<CashierOrderInterface>;
  /**
   * All current cashier session related data
   */
  session: CashierSessionInterface;
  config: {
    online: boolean;
    showLoyaltyPoints: boolean;
    flags?: Record<FlagKeys, boolean>;
    shopSettings?: ShopSettingsInterface;
  };
};

export type CashierV2MachineEvent =
  | { type: "LOAD_CONTEXT"; data: CashierV2MachineContext }
  | {
      type: "OPEN_CASHIER";
      data: Pick<
        CashierV2MachineContext["session"],
        "openingBalance" | "openingNotes"
      >;
    }
  | {
      type: "CLOSE_CASHIER";
      data: Pick<
        CashierV2MachineContext["session"],
        "closingBalance" | "closingNotes"
      >;
    }
  | { type: "SHOW_SALES_INFO" }
  | { type: "CLOSE_SALES_INFO" }
  | { type: "SHOW_PAYMENT_INFO" }
  | { type: "CLOSE_PAYMENT_INFO" }
  | { type: "SHOW_DISCOUNT_INFO" }
  | { type: "CLOSE_DISCOUNT_INFO" }
  | {
      type: "UPDATE_CART_PRODUCTS";
      data: {
        product: CartItemInterface;
        quantity: number;
      };
    }
  | {
      type: "TOGGLE_LOYALTY_POINTS";
      data: boolean;
    }
  | {
      type: "UPDATE_PROMO_DETAILS";
      data: {
        appliedPromoIds: ReadonlyArray<number>;
        manualPromoIds: ReadonlyArray<number>;
        onTheFlyDiscounts: ReadonlyArray<OnTheFlyDiscountInterface>;
        voucherCodes: ReadonlyArray<string>;
      };
    }
  | {
      type: "UPDATE_SALES_INFORMATION";
      data: {
        salesperson?: CashierSalesPersonInterface;
        recipient?: OrderRecipientInterface;
        notes?: string;
      };
    }
  | {
      type: "ADD_CUSTOMER";
      data: OrderRecipientInterface;
    }
  | {
      type: "UPDATE_CUSTOMER";
      data: OrderRecipientInterface | undefined;
    }
  | {
      type: "UPDATE_PAYMENT_INFORMATION";
      data: CashierPaymentInterface;
    }
  | { type: "VALIDATE_PRODUCTS_PROMOS" }
  | { type: "VALIDATE_PROMOS" }
  | { type: "VALIDATE_PROMOS" }
  | {
      type: "UPDATE_PAYMENTS";
      data: {
        payments: ReadonlyArray<CashierV2PaymentInterface>;
      };
    }
  | {
      type: "UPDATE_STORE_CREDIT";
      data: {
        payments: ReadonlyArray<CashierV2PaymentInterface>;
        storeCreditCode?: string;
      };
    }
  | { type: "CONFIRM_ORDER" }
  | {
      type: "CREATE_ORDER";
      data: {
        payments: ReadonlyArray<CashierV2PaymentInterface>;
        referenceNumber?: string;
      };
    }
  | {
      type: "SET_GLOBAL_RULE";
      data: {
        showLoyaltyPoints: boolean;
        shopSettings?: ShopSettingsInterface;
        online: boolean;
        flags?: Record<FlagKeys, boolean>;
      };
    }
  | {
      type: "REMOVE_PROMO";
    }
  | { type: "BACK_TO_CART" }
  | {
      type: "SAVE_DRAFT";
      data: CashierDraftFormValues;
    }
  | { type: "RESET_CART"; data?: CashierV2MachineContext["session"] }
  | {
      type: `error.platform.mutateCashierOrder`;
      data?: {
        message: string;
      };
    }
  | {
      type: "done.invoke.mutateCashierOrder";
      data: CreateCashierOrderResultInterface;
    }
  | {
      type: "done.invoke.loadFromLocalStorage";
      data: {
        cart: CashierV2MachineContext["cart"];
        products: ReadonlyArray<CashierProductInterface>;
      };
    }
  | {
      type: "RESET_CASHIER";
    };

export type CashierV2MachineService = {
  loadCart: {
    data: {
      cart: CashierV2MachineContext["cart"];
      products: ReadonlyArray<CashierProductInterface>;
    };
  };
  openSession: {
    data: CashierV2MachineContext["session"];
  };
  closeSession: {
    data: CashierV2MachineContext["session"];
  };
  getSession: {
    data: CashierV2MachineContext["session"];
  };
  validatePromos: {
    data: CashierV2MachineContext["cart"]["promos"];
  };
  submitOrder: {
    data: CreateCashierOrderResultInterface & { draftUuid: string };
  };
  updateCustomer: {
    data: OrderRecipientInterface;
  };
  submitOfflineOrders: {
    data: CashierV2MachineContext["offlineOrders"];
  };
};

export const CASHIER_V2_MACHINE_STORAGE_KEY = generateStorageKey({
  key: "cashier-v2",
  version: 2,
});

export const initialCashierV2Context: CashierV2MachineContext = {
  offlineOrders: [],
  cart: {
    order: {
      uuid: v4(),
      notes: "",
      createdAt: new Date(),
      recipient: undefined,
      sessionId: "",
      productVariants: [],
      payments: [],
      orderModifier: {
        appliedPromoIds: [],
        manualPromoIds: [],
        voucherCodes: [],
        onTheFlyDiscounts: [],
      },
      total: 0,
      totalPaid: 0,
      useDefaultRecipient: true,
      isCreatedOnOfflineDevice: false,
      payment: {
        paymentMethod: PaymentMethodEnum.QRIS,
        paymentModifier: {} as PaymentModifierInterface,
      },
      appliedPromoIds: [],
      manualPromoIds: [],
      voucherCodes: [],
      onTheFlyDiscounts: [],
    },
    totalOriginalAmount: new Money(0),
    totalDiscountAmount: new Money(0),
    balanceAmount: undefined,
    loyaltyPoints: undefined,
    totalProducts: 0,
    totalVariants: 0,
    promos: [],
    recipients: [],
  },
  session: {
    id: v4(),
    state: CashierSessionStateEnum.DONE,
    openingBalance: 0,
    openedAt: "",
    synced: false,
    pettyCash: new Money(0),
  },
  config: {
    online: true,
    showLoyaltyPoints: false,
  },
};

export const CashierV2Machine = createMachine(
  {
    id: "CashierV2Machine",
    predictableActionArguments: true,
    tsTypes: {} as import("./cashier-v2.machine.typegen").Typegen0,
    schema: {
      context: {} as CashierV2MachineContext,
      events: {} as CashierV2MachineEvent,
      services: {} as CashierV2MachineService,
    },
    context: initialCashierV2Context,
    initial: "initial",
    states: {
      initial: {
        description: "State when user first load cashier",
        always: [
          {
            target: "loadingCashier",
            cond: "isOpen",
          },
          {
            target: "creatingOfflineOrders",
            cond: "hasOfflineOrders",
          },
          {
            target: "closingCashier",
            cond: "hasClosedLocalSession",
          },
        ],
        on: {
          LOAD_CONTEXT: {
            actions: {
              type: "loadLocalContext",
            },
          },
          OPEN_CASHIER: [
            {
              target: "openingCashier",
              cond: "isOnline",
              actions: [
                {
                  type: "startSession",
                },
              ],
            },
            {
              target: "initial",
              actions: [
                {
                  type: "showFailedOpenOfflineSnackbar",
                },
              ],
            },
          ],
        },
      },
      openingCashier: {
        invoke: {
          src: "openSession",
          id: "mutateUpdateCashierSession",
          onDone: [
            {
              target: "cart",
              actions: [
                {
                  type: "updateSession",
                },
                {
                  type: "resetCart",
                },
                {
                  type: "saveLocalContext",
                },
                {
                  type: "showOpenSessionSnackbar",
                },
              ],
            },
          ],
          onError: [
            {
              target: "initial",
              actions: [
                {
                  type: "showFailedOpenSnackbar",
                },
              ],
            },
          ],
        },
      },
      loadingCashier: {
        invoke: {
          src: "getSession",
          id: "getCashierSession",
          onDone: [
            {
              target: "cart",
              actions: [
                {
                  type: "updateSession",
                },
                {
                  type: "saveLocalContext",
                },
              ],
            },
          ],
          onError: [
            {
              target: "cart",
              actions: [
                {
                  type: "setLocalSession",
                },
                {
                  type: "resetCart",
                },
              ],
            },
          ],
        },
      },
      creatingOfflineOrders: {
        invoke: {
          id: "mutateCashierOrders",
          onDone: {
            target: "closingCashier",
            actions: {
              type: "updateOfflineOrders",
            },
          },
          onError: {
            target: "initial",
          },
          src: "submitOfflineOrders",
        },
      },
      cart: {
        description: "State when cashier is ready for new order",
        initial: "ready",
        states: {
          ready: {
            description: "Initial state for cart ready for new order",
            always: {
              target: "loadDraftedOrder",
              cond: "hasOrderId",
            },
            on: {
              UPDATE_CART_PRODUCTS: {
                actions: [
                  {
                    type: "updateProducts",
                  },
                  {
                    type: "updateProductsCount",
                  },
                  {
                    type: "updateVariantsCount",
                  },
                  {
                    type: "updateCartTotal",
                  },
                ],
              },
              TOGGLE_LOYALTY_POINTS: {
                actions: [
                  {
                    type: "updateLoyaltyPoints",
                  },
                  {
                    type: "updateCartTotal",
                  },
                ],
              },
              SHOW_SALES_INFO: {
                target: "salesInformation",
              },
              SHOW_DISCOUNT_INFO: {
                target: "discountInformation",
              },
              SHOW_PAYMENT_INFO: {
                target: "paymentInformation",
              },
              VALIDATE_PRODUCTS_PROMOS: {
                target: "validatingProductsPromos",
              },
              SAVE_DRAFT: {
                actions: [
                  {
                    type: "saveCartDraft",
                  },
                  {
                    type: "showSuccessSaveOrderSnackbar",
                  },
                  {
                    type: "resetCart",
                  },
                ],
              },
              RESET_CART: {
                target: "ready",
                actions: [
                  {
                    type: "resetCart",
                  },
                  {
                    type: "saveLocalContext",
                  },
                ],
              },
            },
          },
          validatingProductsPromos: {
            invoke: {
              src: "validatePromos",
              id: "mutateValidProductsPromos",
              onDone: [
                {
                  target: "ready",
                  actions: [
                    {
                      type: "updatePromos",
                    },
                    {
                      type: "updateCartTotal",
                    },
                  ],
                },
              ],
              onError: [
                {
                  target: "ready",
                  actions: [
                    {
                      type: "removePromos",
                    },
                    {
                      type: "updateCartTotal",
                    },
                  ],
                },
              ],
            },
          },
          validatingPromos: {
            invoke: {
              src: "validatePromos",
              id: "mutateValidPromos",
              onDone: [
                {
                  target: "discountInformation",
                  actions: [
                    {
                      type: "updatePromos",
                    },
                    {
                      type: "updateCartTotal",
                    },
                  ],
                },
              ],
              onError: [
                {
                  target: "discountInformation",
                  actions: [
                    {
                      type: "removePromos",
                    },
                    {
                      type: "updateCartTotal",
                    },
                  ],
                },
              ],
            },
          },
          salesInformation: {
            description: "State to handle update sales information",
            on: {
              UPDATE_SALES_INFORMATION: {
                target: "salesInformation",
                actions: [
                  {
                    type: "updateCustomer",
                  },
                  {
                    type: "updateSalesperson",
                  },
                  {
                    type: "updateNotes",
                  },
                  {
                    type: "updateCartTotal",
                  },
                ],
              },
              ADD_CUSTOMER: {
                target: "salesInformation",
                actions: [
                  {
                    type: "updateCustomer",
                  },
                  {
                    type: "updateCartTotal",
                  },
                ],
              },
              CLOSE_SALES_INFO: {
                target: "ready",
              },
            },
          },
          discountInformation: {
            description: "State to show and add discount",
            on: {
              CLOSE_DISCOUNT_INFO: {
                target: "ready",
              },
              UPDATE_PROMO_DETAILS: {
                target: "validatingPromos",
                actions: [
                  {
                    type: "updateCartPromos",
                  },
                  {
                    type: "updateCartTotal",
                  },
                ],
              },
            },
          },
          loadDraftedOrder: {
            invoke: {
              src: "loadCart",
              id: "loadFromLocalStorage",
              onDone: [
                {
                  target: "validatingProductsPromos",
                  actions: [
                    {
                      type: "updateCart",
                    },
                    {
                      type: "showLoadDraftSnackbar",
                    },
                    {
                      type: "resetDraftPathname",
                    },
                  ],
                },
              ],
              onError: [
                {
                  target: "ready",
                  actions: {
                    type: "resetCart",
                  },
                },
              ],
            },
          },
          paymentInformation: {
            description: "State to handle payment type",
            on: {
              UPDATE_PAYMENT_INFORMATION: {
                target: "paymentInformation",
                actions: {
                  type: "updatePayment",
                },
              },
              CLOSE_PAYMENT_INFO: {
                target: "validatingProductsPromos",
              },
            },
          },
        },
        on: {
          SET_GLOBAL_RULE: {
            target: "cart",
            actions: [
              {
                type: "setGlobalRule",
              },
              {
                type: "updateCartTotal",
              },
            ],
          },
          REMOVE_PROMO: {
            target: "cart",
            actions: [
              {
                type: "removePromos",
              },
              {
                type: "updateCartTotal",
              },
            ],
          },
          CONFIRM_ORDER: [
            {
              target: "payment",
              cond: "isCashTransaction",
            },
            {
              target: "payment",
              cond: "isSplitPayment",
            },
            {
              target: "confirmingOrder",
              actions: [
                {
                  type: "updateTotalPaid",
                },
              ],
            },
          ],
          CLOSE_CASHIER: {
            target: "closingCashier",
            actions: {
              type: "closeLocalSession",
            },
          },
        },
      },
      payment: {
        description: "State to handle payment for order",
        on: {
          UPDATE_PAYMENTS: {
            target: "validatingPaymentPromos",
            actions: [
              {
                type: "updatePayments",
              },
            ],
          },
          UPDATE_STORE_CREDIT: {
            target: "validatingPaymentPromos",
            actions: [
              {
                type: "updatePayments",
              },
              {
                type: "updateStoreCredit",
              },
            ],
          },
          CREATE_ORDER: {
            target: "confirmingOrder",
            actions: [
              {
                type: "updatePayments",
              },
              {
                type: "updateTotalPaid",
              },
            ],
          },
          BACK_TO_CART: {
            target: "invalidatingPaymentPromos",
            actions: [
              {
                type: "clearPayments",
              },
            ],
          },
          SET_GLOBAL_RULE: {
            target: "payment",
            actions: [
              {
                type: "setGlobalRule",
              },
              {
                type: "updateCartTotal",
              },
            ],
          },
        },
      },
      validatingPaymentPromos: {
        invoke: {
          src: "validatePromos",
          id: "mutateValidPaymentPromos",
          onDone: [
            {
              target: "payment",
              actions: [
                {
                  type: "updatePromos",
                },
                {
                  type: "updateCartTotal",
                },
              ],
            },
          ],
          onError: [
            {
              target: "payment",
              actions: [
                {
                  type: "removePromos",
                },
                {
                  type: "updateCartTotal",
                },
              ],
            },
          ],
        },
      },
      invalidatingPaymentPromos: {
        invoke: {
          src: "validatePromos",
          id: "mutateValidPaymentPromos",
          onDone: [
            {
              target: "cart",
              actions: [
                {
                  type: "updatePromos",
                },
                {
                  type: "updateCartTotal",
                },
              ],
            },
          ],
          onError: [
            {
              target: "payment",
              actions: [
                {
                  type: "removePromos",
                },
                {
                  type: "updateCartTotal",
                },
              ],
            },
          ],
        },
      },
      confirmingOrder: {
        invoke: {
          src: "submitOrder",
          id: "mutateCashierOrder",
          onDone: [
            {
              target: "orderSuccess",
              actions: [
                {
                  type: "updateReceiptKey",
                },
                {
                  type: "removeDraftOrder",
                },
              ],
            },
          ],
          onError: [
            {
              target: "cart",
              cond: "isOnline",
              actions: [
                {
                  type: "removePromos",
                },
                {
                  type: "showFailedConfirmOrderSnackbar",
                },
              ],
            },
            {
              target: "orderSuccess",
              actions: [
                {
                  type: "updateLocalOrder",
                },
                {
                  type: "saveLocalContext",
                },
              ],
            },
          ],
        },
      },
      orderSuccess: {
        entry: [
          {
            type: "updatePettyCash",
          },
        ],
        on: {
          RESET_CART: {
            target: "cart",
            actions: [
              {
                type: "resetCart",
              },
              {
                type: "updateSession",
              },
              {
                type: "saveLocalContext",
              },
            ],
          },
          ADD_CUSTOMER: {
            target: "updatingCustomer",
            actions: {
              type: "updateCustomer",
            },
          },
          UPDATE_CUSTOMER: {
            target: "updatingCustomer",
            actions: {
              type: "updateCustomer",
            },
          },
        },
      },
      updatingCustomer: {
        invoke: {
          src: "updateCustomer",
          id: "updateRecipientMutation",
          onDone: {
            target: "orderSuccess",
          },
          onError: {
            target: "orderSuccess",
            actions: {
              type: "showFailedUpdateCustomerSnackbar",
            },
          },
        },
      },
      closingCashier: {
        invoke: {
          src: "closeSession",
          id: "mutateUpdateCashierSession",
          onDone: [
            {
              target: "closedCashier",
              actions: [
                {
                  type: "previewSession",
                },
              ],
            },
          ],
          onError: [
            {
              target: "closedCashier",
              actions: [
                {
                  type: "previewSession",
                },
                {
                  type: "setLocalSession",
                },
              ],
            },
          ],
        },
      },
      closedCashier: {
        on: {
          RESET_CASHIER: {
            target: "initial",
            actions: [
              {
                cond: "isOnline",
                type: "clearLocalContext",
              },
              {
                cond: (context: CashierV2MachineContext) =>
                  !context.config.online,
                type: "endSession",
              },
              {
                type: "saveLocalContext",
              },
            ],
          },
        },
      },
    },
    preserveActionOrder: true,
  },
  {
    actions: {
      setLocalSession: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          if (event.type === "OPEN_CASHIER") {
            return {
              ...context,
              session: {
                ...context.session,
                synced: false,
              },
            };
          }

          return context;
        }
      ),
      startSession: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          if (event.type === "OPEN_CASHIER") {
            const newSession: CashierV2MachineContext["session"] = {
              id: v4(),
              state: CashierSessionStateEnum.OPENING,
              openedAt: dayjs().format(),
              synced: false,
              closedAt: undefined,
              closingBalance: undefined,
              closingNotes: undefined,
              pettyCash: new Money(event.data.openingBalance),
              ...event.data,
            };

            return {
              ...context,
              session: {
                ...newSession,
              },
            };
          }

          return context;
        }
      ),
      loadLocalContext: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          if (event.type === "LOAD_CONTEXT") {
            return {
              ...context,
              ...event.data,
            };
          }

          return context;
        }
      ),
      resetCart: assign((context, event) => {
        const initialContext = { ...initialCashierV2Context };

        return {
          ...context,
          cart: {
            ...context.cart,
            ...initialContext.cart,
            order: {
              ...context.cart.order,
              ...initialContext.cart.order,
              sessionId: context.session.id,
            },
          },
        };
      }),
      updateCart: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          if (event.type === "done.invoke.loadFromLocalStorage") {
            return {
              ...context,
              cart: event.data.cart,
            };
          }

          return context;
        }
      ),
      updateNotes: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          if (event.type === "UPDATE_SALES_INFORMATION") {
            return {
              ...context,
              cart: {
                ...context.cart,
                order: {
                  ...context.cart.order,
                  notes: event.data.notes as string,
                },
              },
            };
          }

          return context;
        }
      ),
      updateCartPromos: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          // TODO: Simplify this logic
          if (event.type === "UPDATE_PROMO_DETAILS") {
            const {
              manualPromoIds,
              appliedPromoIds,
              onTheFlyDiscounts,
              voucherCodes,
            } = event.data;

            const promos = context.cart.promos.map((promo) => {
              const isSelected = manualPromoIds.includes(promo.id);
              const promoModel = new StorePromo(promo);
              const wasSelected = promoModel.grantedReward;

              if (isSelected && !wasSelected) {
                // Promo is selected
                return {
                  ...promo,
                  grantedReward: {
                    ...promo.grantedReward,
                    item: {
                      ...promo.grantedReward!.item!,
                      name: "Selected Promo",
                    },
                  },
                };
              }

              if (isSelected && wasSelected) {
                // Promo is selected
                return promo;
              }

              if (wasSelected && promo.isManualApply) {
                // Promo was selected but is not anymore and is manual apply
                return {
                  ...promo,
                  grantedReward: {},
                };
              }

              // Promo is not selected and is not manual apply
              return promo;
            });

            return {
              ...context,
              cart: {
                ...context.cart,
                promos,
                order: {
                  ...context.cart.order,
                  appliedPromoIds,
                  voucherCodes,
                  onTheFlyDiscounts,
                  orderModifier: {
                    ...context.cart.order.orderModifier,
                    appliedPromoIds,
                    voucherCodes,
                    onTheFlyDiscounts,
                  },
                },
              },
            };
          }

          return context;
        }
      ),
      updatePromos: assign(
        (
          context,
          event: {
            type: string;
            data: CashierV2MachineContext["cart"]["promos"];
          }
        ) => {
          const promoModifier = event.data
            ? getPromoDetailsPayload(event.data)
            : context.cart.order.orderModifier;

          return {
            ...context,
            cart: {
              ...context.cart,
              promos: event.data,
              order: {
                ...context.cart.order,
                ...promoModifier,
                orderModifier: {
                  ...context.cart.order.orderModifier,
                  ...promoModifier,
                },
              },
            },
          };
        }
      ),
      updatePayment: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          if (event.type === "UPDATE_PAYMENT_INFORMATION") {
            return {
              ...context,
              cart: {
                ...context.cart,
                order: {
                  ...context.cart.order,
                  payment: {
                    ...context.cart.order.payment,
                    ...event.data,
                  },
                },
              },
            };
          }

          return context;
        }
      ),
      updatePayments: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          if (
            event.type === "UPDATE_PAYMENTS" ||
            event.type === "UPDATE_STORE_CREDIT" ||
            event.type === "CREATE_ORDER"
          ) {
            const totalPaymentAmount = event.data.payments.reduce(
              (acc, payment) => acc.add(payment.amount),
              new Money(0, { rounded: true })
            );

            const balance = totalPaymentAmount.subtract(
              context.cart.order.total
            );

            const updatedPayments: ReadonlyArray<CashierV2PaymentInterface> =
              event.data.payments.map((payment) => {
                return payment.method === CashierV2PaymentMethodEnum.CASH
                  ? {
                      ...payment,
                      amount:
                        balance.integer > 0
                          ? payment.amount - balance.integer
                          : payment.amount,
                    }
                  : payment;
              });

            return {
              ...context,
              cart: {
                ...context.cart,
                balanceAmount: balance.integer > 0 ? balance : undefined,
                order: {
                  ...context.cart.order,
                  payments: updatedPayments,
                },
              },
            };
          }

          return context;
        }
      ),
      updateStoreCredit: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          if (event.type === "UPDATE_STORE_CREDIT") {
            return {
              ...context,
              cart: {
                ...context.cart,
                order: {
                  ...context.cart.order,
                  orderModifier: {
                    ...context.cart.order.orderModifier,
                    storeCreditCode: event.data.storeCreditCode,
                  },
                },
              },
            };
          }

          return context;
        }
      ),
      clearPayments: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          if (event.type === "BACK_TO_CART") {
            return {
              ...context,
              cart: {
                ...context.cart,
                balanceAmount: undefined,
                order: {
                  ...context.cart.order,
                  payments: [],
                  orderModifier: {
                    ...context.cart.order.orderModifier,
                    storeCreditCode: undefined,
                  },
                },
              },
            };
          }

          return context;
        }
      ),
      updateSession: assign(
        (
          context,
          event: {
            type: string;
            data: CashierV2MachineContext["session"];
          }
        ) => {
          return {
            ...context,
            cart: {
              ...context.cart,
              order: {
                ...context.cart.order,
                sessionId: event.data?.id ?? context.session.id,
              },
            },
            session: {
              ...context.session,
              ...event.data,
            },
          };
        }
      ),
      closeLocalSession: assign(
        (
          context,
          event: {
            type: string;
            data: CashierV2MachineContext["session"];
          }
        ) => {
          return {
            ...context,
            session: {
              ...context.session,
              ...event.data,
              state: CashierSessionStateEnum.CLOSING,
            },
          };
        }
      ),
      previewSession: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          return {
            ...context,
            session: {
              ...context.session,
              state: CashierSessionStateEnum.PREVIEW,
            },
          };
        }
      ),
      endSession: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          return {
            ...context,
            session: {
              ...context.session,
              state: CashierSessionStateEnum.DONE,
            },
          };
        }
      ),
      updateCustomer: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          if (event.type === "UPDATE_SALES_INFORMATION") {
            const hasLoyaltyPoints = context.config.showLoyaltyPoints;
            const showLoyaltyPoints =
              hasLoyaltyPoints && !!event.data.recipient;

            return {
              ...context,
              cart: {
                ...context.cart,
                loyaltyPoints: showLoyaltyPoints
                  ? new Money(event.data.recipient?.loyaltyPoints ?? 0)
                  : undefined,
                order: {
                  ...context.cart.order,
                  useDefaultRecipient: !event.data.recipient,
                  recipient: event.data.recipient,
                },
              },
            };
          }

          if (event.type === "ADD_CUSTOMER") {
            return {
              ...context,
              cart: {
                ...context.cart,
                recipients: [event.data, ...context.cart.recipients],
                order: {
                  ...context.cart.order,
                  useDefaultRecipient: !event.data,
                  recipient: event.data,
                },
              },
            };
          }

          if (event.type === "UPDATE_CUSTOMER") {
            return {
              ...context,
              cart: {
                ...context.cart,
                order: {
                  ...context.cart.order,
                  useDefaultRecipient: !event.data,
                  recipient: event.data,
                },
              },
            };
          }

          return context;
        }
      ),
      updateProducts: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          if (event.type === "UPDATE_CART_PRODUCTS") {
            const { product, quantity } = event.data;

            const productIndex = findIndex(
              [...(context.cart.order.productVariants ?? [])],
              (data) =>
                data.masterProductVariantId === product.masterProductVariantId
            );

            const newProductVariants = [
              ...(context.cart.order.productVariants ?? []),
            ];

            if (productIndex !== -1) {
              if (quantity === 0) {
                newProductVariants.splice(productIndex, 1);
              } else {
                newProductVariants[productIndex] = { ...product, quantity };
              }
            } else {
              newProductVariants.push({ ...product, quantity });
            }

            const filteredProductVariants = newProductVariants.filter(
              (product) => product.quantity !== 0
            );

            return {
              ...context,
              cart: {
                ...context.cart,
                order: {
                  ...context.cart.order,
                  productVariants: filteredProductVariants,
                },
              },
            };
          }

          return context;
        }
      ),
      updateCartTotal: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context) => {
          const {
            originalPrice,
            discountPrice,
            totalPrice,
            roundedUpAmount,
            loyaltyPointsUsed,
          } = getCashierPricing({
            items: context.cart.order.productVariants,
            promos: context.cart.promos.map((promo) => new StorePromo(promo)),
            loyaltyPoints: context.cart.order.orderModifier.loyaltyPoints
              ? context.cart.loyaltyPoints?.integer
              : undefined,
            hasRoundingUp: context.config.shopSettings?.isRoundingUpPrice,
            hasPayment: true,
          });

          return {
            ...context,
            cart: {
              ...context.cart,
              totalOriginalAmount: originalPrice,
              totalDiscountAmount: discountPrice,
              order: {
                ...context.cart.order,
                orderModifier: {
                  ...context.cart.order.orderModifier,
                  roundingUpAmount: roundedUpAmount?.integer,
                  loyaltyPoints: loyaltyPointsUsed?.integer,
                },
                payment: {
                  ...context.cart.order.payment,
                  paymentModifier: {
                    ...(context.cart.order.payment
                      .paymentModifier as PaymentModifierInterface),
                    roundingUpAmount: roundedUpAmount?.integer,
                    loyaltyPoints: loyaltyPointsUsed?.integer,
                  },
                },
                total: totalPrice.integer,
              },
            },
          };
        }
      ),
      updateLoyaltyPoints: assign<
        CashierV2MachineContext,
        CashierV2MachineEvent
      >((context, event) => {
        if (event.type === "TOGGLE_LOYALTY_POINTS") {
          return {
            ...context,
            cart: {
              ...context.cart,
              order: {
                ...context.cart.order,
                orderModifier: {
                  ...context.cart.order.orderModifier,
                  loyaltyPoints: event.data
                    ? context.cart.loyaltyPoints?.integer
                    : 0,
                },
              },
            },
          };
        }

        return context;
      }),
      updateLocalOrder: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          const newOfflineOrders = [
            ...(context.offlineOrders ?? []),
            context.cart.order,
          ];

          return {
            ...context,
            offlineOrders: newOfflineOrders,
          };
        }
      ),
      updateSalesperson: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          if (event.type === "UPDATE_SALES_INFORMATION") {
            return {
              ...context,
              cart: {
                ...context.cart,
                salesperson: event.data.salesperson,
                order: {
                  ...context.cart.order,
                  salespersonId: event.data.salesperson?.value,
                },
              },
            };
          }

          return context;
        }
      ),
      updateProductsCount: assign<
        CashierV2MachineContext,
        CashierV2MachineEvent
      >((context, event) => {
        const uniqueVariants = new Set(
          context.cart.order.productVariants.map((variant) => variant.id)
        );

        return {
          ...context,
          cart: {
            ...context.cart,
            totalProducts: uniqueVariants.size,
          },
        };
      }),
      updateVariantsCount: assign<
        CashierV2MachineContext,
        CashierV2MachineEvent
      >((context, event) => {
        const totalVariants = context.cart.order.productVariants.reduce(
          (acc, variant) => acc + variant.quantity,
          0
        );

        return {
          ...context,
          cart: {
            ...context.cart,
            totalVariants: totalVariants ?? 0,
          },
        };
      }),
      updateTotalPaid: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context, event) => {
          if (event.type === "CONFIRM_ORDER") {
            const isInvoicePayment =
              context.cart.order.payment.paymentMethod ===
              PaymentMethodEnum.INVOICE;

            return {
              ...context,
              cart: {
                ...context.cart,
                order: {
                  ...context.cart.order,
                  totalPaid: isInvoicePayment ? 0 : context.cart.order.total,
                },
              },
            };
          }

          if (event.type === "CREATE_ORDER") {
            const totalPaymentsAmount = context.cart.order.payments.reduce(
              (acc, payment) => acc.add(payment.amount),
              new Money(0, { rounded: true })
            );

            const totalPaid = totalPaymentsAmount.add(
              context.cart.balanceAmount ?? 0
            );

            return {
              ...context,
              cart: {
                ...context.cart,
                order: {
                  ...context.cart.order,
                  totalPaid: totalPaid.integer,
                  orderModifier: {
                    ...context.cart.order.orderModifier,
                    referenceNumber: event.data.referenceNumber,
                  },
                },
              },
              session: {
                ...context.session,
              },
            };
          }

          return context;
        }
      ),
      updateReceiptKey: assign(
        (
          context,
          event: {
            type: string;
            data: CreateCashierOrderResultInterface;
          }
        ) => {
          if (!context.cart.order) {
            return context;
          }

          return {
            ...context,
            cart: {
              ...context.cart,
              receiptKey: event.data.order.id.toString(36),
              order: {
                ...context.cart.order,
                uuid: context.config.online
                  ? event.data.order.uuid ?? context.cart.order.uuid
                  : context.cart.order.uuid,
                orderModifier: {
                  ...context.cart.order.orderModifier,
                  ...event.data.orderModifier,
                },
                payment: {
                  ...context.cart.order.payment,
                  paymentModifier: {
                    ...context.cart.order.payment.paymentModifier,
                    ...event.data.orderModifier,
                  },
                },
              },
            },
          };
        }
      ),
      updateOfflineOrders: assign(
        (
          context,
          event: {
            type: string;
            data: CashierV2MachineContext["offlineOrders"];
          }
        ) => {
          return {
            ...context,
            offlineOrders: event.data,
          };
        }
      ),
      updatePettyCash: assign<CashierV2MachineContext, CashierV2MachineEvent>(
        (context) => {
          const cashTotal = context.cart.order.payments.reduce(
            (acc, payment) =>
              payment.method === CashierV2PaymentMethodEnum.CASH
                ? acc.add(payment.amount)
                : acc,
            new Money(0)
          );

          return {
            ...context,
            session: {
              ...context.session,
              pettyCash: context.session.pettyCash.add(cashTotal),
            },
          };
        }
      ),
      setGlobalRule: assign<
        CashierV2MachineContext,
        {
          type: string;
          data: {
            showLoyaltyPoints: boolean;
            shopSettings?: ShopSettingsInterface;
            online: boolean;
            flags?: Record<FlagKeys, boolean>;
          };
        }
      >((context, event) => {
        return {
          ...context,
          config: {
            ...context.config,
            ...event.data,
          },
        };
      }),
    },
    guards: {
      isOnline: (context) => {
        return context.config.online;
      },
      isOpen: (context) => {
        return context.session.state === "idle";
      },
      hasOfflineOrders: (context) => {
        return !!context.offlineOrders.length;
      },
      hasClosedLocalSession: (context) => {
        return context.session.state === "closing";
      },
      isCashTransaction: (context) => {
        return context.config.flags?.["pos/split-payment"]
          ? true
          : context.cart.order.payment.paymentMethod === PaymentMethodEnum.CASH;
      },
      isSplitPayment: (context) => {
        return context.config.flags?.["pos/split-payment"] ?? false;
      },
    },
  }
);

export const CashierV2Context = createContext({
  context: initialCashierV2Context,
} as {
  cashierActor: InterpreterFrom<typeof CashierV2Machine>;
  /**
   * @deprecated
   * Please use `useSelector` to access context
   */
  context: CashierV2MachineContext;
  /**
   * @deprecated
   * Please use `useSelector` to access state
   */
  state: StateValueFrom<typeof CashierV2Machine>;
});

export const useCashierV2Context = () => {
  const context = useContext(CashierV2Context);

  if (!context) {
    throw new Error(
      "useCashierV2Context must be used within a CashierV2Context.Provider"
    );
  }

  return context;
};

export default CashierV2Machine;
