"use client";

import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { BaseButton } from "@saas/shared/ui";

import { useIsClient } from "usehooks-ts";

export interface NoAccessInfoProps {
  handleLogout?: () => void;
  variant?: "dashboard" | "pos";
  onRedirection?: () => void;
}

const useTitle = () => {
  const [title, setTitle] = useState("");
  const isClient = useIsClient();

  useEffect(() => {
    if (isClient) setTitle(window.document.title);
  }, []);

  return title;
};

export const NoAccess = ({
  variant = "dashboard",
  handleLogout,
  onRedirection,
}: NoAccessInfoProps) => {
  const router = useRouter();
  const title = useTitle();

  const handleRedirection = () => {
    if (onRedirection) return onRedirection();

    router.back();
  };

  return (
    <div className={"grid h-[calc(100vh-120px)] place-content-center gap-3"}>
      <div className={"flex flex-col gap-6"}>
        <Image
          src={"/images/no-result.svg"}
          alt={"no-access"}
          width={280}
          height={187}
          data-testid={"no-access-info__img__img-no-access-info"}
        />

        <div className={"flex flex-col gap-2 text-center"}>
          <h2
            className={"headline-h2 text-he"}
            data-testid={"no-access-info__text__title"}
          >
            {`Kamu tidak memiliki akses ke halaman ${
              variant === "pos" ? "ini" : title
            }!`}
          </h2>

          <span
            className={"text-he body-b1"}
            data-testid={"no-access-info__text__subtitle"}
          >
            Hubungi akun yang mengundangmu untuk memberikan akses.
          </span>
        </div>
      </div>

      <div className={"flex justify-center"}>
        {variant === "pos" ? (
          <BaseButton
            variant={"primary"}
            size={"big"}
            onClick={handleLogout}
            testid={"no-access-info__button__logout"}
          >
            Keluar dari Melaka POS
          </BaseButton>
        ) : (
          <BaseButton
            variant={"primary"}
            size={"big"}
            onClick={handleRedirection}
            testid={"no-access-info__button__kembali-ke-halaman-sebelumnya"}
          >
            Kembali ke Halaman Sebelumnya
          </BaseButton>
        )}
      </div>
    </div>
  );
};

export default NoAccess;
