import {
  formatLocaleCurrency,
  FormatLocaleCurrencyOptions,
  MoneyData,
} from "../..";

export type FormatMoneyInput = number;

export type FormatMoneyOutput = MoneyData;

export const formatMoney = (
  input?: FormatMoneyInput,
  options?: FormatLocaleCurrencyOptions
): FormatMoneyOutput => {
  const formatted = formatLocaleCurrency(input ?? 0, options);

  const value = formatted.match(/[\d,.]+/g)?.join("") || "";
  const currency = formatted.match(/[^\d.,\s]+/)?.join("") || "";

  return {
    integer: input ?? 0,
    currency,
    value,
  };
};

export default formatMoney;
