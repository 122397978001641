"use client";

import { useCallback, useMemo } from "react";

import { useSessionQuery } from "@saas/account/data-access";
import {
  AccessesKeyEnum,
  accessRouteMapping,
  getProfileFromSession,
} from "@saas/account/utils";
import { subaccountRoutes } from "@saas/config/dashboard";
import { env } from "@saas/config/shared";

import { isNull } from "lodash-es";

export type UsePermissionsReturnType = {
  hasPermission: (route: string, state?: "dashboard" | "pos") => boolean;
  hasParentId: string | null;
  accesses?: AccessesKeyEnum[];
  hasOrderActionPermission: boolean;
  hasMarketplaceActionPermission: boolean;
};

export const usePermissions = (): UsePermissionsReturnType => {
  const { data: accesses } = useSessionQuery({
    select: (data) => {
      const profile = getProfileFromSession(data);

      return profile?.accesses ?? [];
    },
  });

  const { data: parentUserId } = useSessionQuery({
    select: (data) => {
      const profile = getProfileFromSession(data);

      return profile?.parentUserId ?? null;
    },
  });

  const accessRoutes = useMemo(() => {
    const accessRoute = accesses?.map((access) => {
      return access.includes("all") || access.includes("action")
        ? { full: accessRouteMapping[access] }
        : { view: accessRouteMapping[access] };
    });

    if (!accessRoute?.length) {
      return [];
    }

    return [...new Set([{ full: subaccountRoutes[0] }, ...accessRoute])];
  }, []);

  const checkPosAllAccess = () => {
    return accesses ? accesses.includes(AccessesKeyEnum.POS_ALL) : false;
  };

  const checkOrderAccess = () => {
    return accesses?.includes(AccessesKeyEnum.POS_ALL)
      ? accesses.includes(AccessesKeyEnum.ORDER_ACTION) ||
          accesses.includes(AccessesKeyEnum.ORDER_VIEW)
      : false;
  };

  const checkPosAccess = useCallback((currentRoute: string) => {
    return currentRoute !== "/" ? checkOrderAccess() : checkPosAllAccess();
  }, []);

  const normalizeRoute = (route: string) => {
    const url = new URL(route, env.DASHBOARD_URL);
    return url.pathname;
  };

  const checkRouteAccess = useCallback((currentRoute: string) => {
    const normalizedRoute = normalizeRoute(currentRoute);

    return accessRoutes.some((routeObj) => {
      const [key, value] = Object.entries(routeObj)[0];
      const normalizedValue = normalizeRoute(value);

      if (normalizedValue !== "/" && normalizedRoute !== "/") {
        return key === "full" || normalizedValue === "/order"
          ? normalizedRoute.includes(normalizedValue)
          : normalizedValue.includes(normalizedRoute);
      }

      return normalizedRoute === "/" && normalizedValue === "/";
    });
  }, []);

  const hasPermission = useCallback(
    (currentRoute: string, state: "dashboard" | "pos" = "dashboard") =>
      state === "pos"
        ? checkPosAccess(currentRoute)
        : checkRouteAccess(currentRoute),

    [checkPosAccess, checkRouteAccess]
  );

  const hasParentId = useMemo(() => {
    if (parentUserId) {
      return parentUserId;
    }

    return null;
  }, [parentUserId]);

  const hasOrderActionPermission =
    accesses?.includes(AccessesKeyEnum.ORDER_ACTION) || isNull(hasParentId);

  const hasMarketplaceActionPermission =
    accesses?.includes(AccessesKeyEnum.MARKETPLACE_CONNECTION_ACTION) ||
    isNull(hasParentId);

  return {
    hasParentId,
    hasPermission,
    accesses,
    hasOrderActionPermission,
    hasMarketplaceActionPermission,
  };
};

export default usePermissions;
