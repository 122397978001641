"use client";

import { classNames, useUniqueId } from "@saas/shared/utils";

const Variant = {
  COLOR: "color",
  WHITE: "white",
  BLUE: "blue",
  BLACK: "black",
  POS: "pos",
  CHAT: "chat",
} as const;

type VariantKeys = keyof typeof Variant;
type VariantValues = (typeof Variant)[VariantKeys];

export interface LogoProps {
  className?: string;
  variant?: VariantValues;
}

export const Logo = ({ className, variant = "color" }: LogoProps) => {
  const firstGradientId = useUniqueId("gradient-one");
  const thirdGradientId = useUniqueId("gradient-three");

  const variantSVG = {
    [Variant.COLOR]: (
      <>
        <g clipPath={"url(#clip0_1135_3343)"}>
          <g clipPath={"url(#clip1_1135_3343)"}>
            <path
              d={
                "M18.4253 20.2621V31.3433C18.4253 32.2581 19.1667 33 20.0808 33H21.7323C22.9535 33 23.9435 32.0093 23.9435 30.7872V15.4791C23.9435 14.9774 23.3411 14.7215 22.9803 15.0702L18.991 18.9279C18.629 19.2778 18.4253 19.7592 18.4253 20.2621Z"
              }
              fill={"#006FFF"}
            />
            <path
              d={
                "M4.07719 30.7873C4.07719 32.0093 5.06724 33 6.28846 33H7.9399C8.85406 33 9.59543 32.2582 9.59543 31.3434V21.8353C9.59543 21.3598 9.41272 20.9018 9.08523 20.5572L5.05732 16.3157C4.70356 15.9436 4.07719 16.1936 4.07719 16.7077V30.7873Z"
              }
              fill={"#006FFF"}
            />
            <path
              d={
                "M38.1539 17.794C37.0144 17.0276 35.6169 16.6392 33.9999 16.6392C32.4798 16.6392 31.1144 16.9896 29.9416 17.6819C28.7677 18.3747 27.8343 19.3589 27.167 20.6084C26.5021 21.8532 26.1653 23.3335 26.1653 25.007C26.1653 26.5457 26.5103 27.9318 27.191 29.127C27.8728 30.3245 28.8296 31.2772 30.0356 31.9595C31.2393 32.6406 32.6479 32.9859 34.2217 32.9859C35.7068 32.9859 37.095 32.5898 38.3477 31.8082C39.6051 31.0237 40.5379 29.9092 41.1205 28.4944C41.1777 28.3559 41.1013 28.1988 40.9577 28.1573L37.1802 27.0732C37.0605 27.0387 36.9338 27.0971 36.8825 27.2104C36.6268 27.773 36.2532 28.2081 35.771 28.5043C35.2859 28.8022 34.6899 28.9535 33.9993 28.9535C32.8937 28.9535 32.0834 28.6095 31.5218 27.9021C31.1564 27.4412 30.9054 26.843 30.7746 26.1204H41.0131C41.1433 26.1204 41.2519 26.0205 41.263 25.8908C41.4229 24.0444 41.2303 22.4117 40.6909 21.0378C40.148 19.6522 39.294 18.561 38.1539 17.794ZM36.8994 22.8106H30.9019C31.0449 22.2896 31.2539 21.8491 31.5248 21.4987C32.0863 20.7737 32.9503 20.4215 34.1668 20.4215C35.1925 20.4215 35.9053 20.7171 36.3454 21.3252C36.6017 21.6792 36.7873 22.1774 36.8994 22.8106Z"
              }
              fill={"#006FFF"}
            />
            <path
              d={
                "M46.9517 11.3872H44.0504V33H48.8314V13.2687C48.8314 12.2295 47.9902 11.3872 46.9517 11.3872Z"
              }
              fill={"#006FFF"}
            />
            <path
              d={
                "M64.8105 19.4968C64.2565 18.4465 63.4282 17.697 62.3482 17.2683C61.2951 16.8506 60.0774 16.6392 58.7278 16.6392C56.8755 16.6392 55.3858 17.0486 54.3006 17.8559C53.2154 18.6632 52.4652 19.7258 52.0712 21.0138C52.0309 21.1453 52.1039 21.2849 52.2352 21.3263L55.652 22.4105C55.781 22.4514 55.9193 22.3825 55.9643 22.2545C56.1832 21.6313 56.5527 21.2107 57.0938 20.9688C57.6607 20.7159 58.2106 20.5874 58.7278 20.5874C59.6554 20.5874 60.3156 20.7737 60.6915 21.1412C60.9373 21.3818 61.1037 21.7271 61.1883 22.1693C60.6407 22.251 60.1183 22.3287 59.6221 22.4017C58.6484 22.546 57.7652 22.6985 56.9975 22.8556C56.22 23.0151 55.5399 23.2009 54.9748 23.4082C54.1418 23.7149 53.4664 24.1039 52.9679 24.5643C52.4641 25.0292 52.094 25.5742 51.8681 26.1835C51.6457 26.7828 51.533 27.4528 51.533 28.1748C51.533 29.0481 51.7373 29.8589 52.1413 30.5833C52.547 31.3123 53.15 31.9011 53.9334 32.3345C54.7127 32.7656 55.6718 32.9841 56.7839 32.9841C58.1154 32.9841 59.2368 32.7475 60.1165 32.2808C60.6454 32.0004 61.1574 31.6102 61.6442 31.1184V32.9952H64.4643C65.0096 32.9952 65.4515 32.553 65.4515 32.0074V23.1448C65.4515 22.4543 65.4229 21.8194 65.3668 21.2574C65.3079 20.6715 65.1205 20.0792 64.8105 19.4968ZM57.5889 29.5096C57.1884 29.5096 56.8417 29.4506 56.5591 29.3338C56.2871 29.2216 56.0845 29.0668 55.9403 28.8589C55.7979 28.655 55.7284 28.4132 55.7284 28.1199C55.7284 27.9091 55.7716 27.7245 55.8598 27.5551C55.9485 27.3857 56.0927 27.2244 56.2888 27.0761C56.4967 26.9189 56.7786 26.7688 57.1207 26.6315C57.4336 26.5153 57.8025 26.4061 58.217 26.3068C58.6361 26.2063 59.1971 26.093 59.8836 25.9691C60.2525 25.9025 60.688 25.8266 61.1813 25.7425C61.1737 25.9218 61.1638 26.114 61.1515 26.3184C61.1212 26.8255 61.0149 27.2735 60.8351 27.6497C60.7435 27.8407 60.5631 28.0872 60.2981 28.3828C60.0371 28.6737 59.6781 28.9377 59.231 29.1679C58.792 29.3951 58.2398 29.5096 57.5889 29.5096Z"
              }
              fill={"#006FFF"}
            />
            <path
              d={
                "M81.9828 17.0562H78.5246C78.0185 17.0562 77.5439 17.3027 77.2526 17.7169L73.6456 22.8451C73.4506 23.1226 73.0146 22.9841 73.0146 22.6453V12.9725C73.0146 12.1015 72.3088 11.3953 71.4384 11.3953H68.2114L68.1852 31.5804C68.184 32.3573 68.8133 32.9876 69.5897 32.9876H73.0152V26.9469C73.0152 26.6099 73.4471 26.4703 73.6439 26.7442L77.3647 31.9209C77.6572 32.3275 78.1271 32.5688 78.6274 32.5688H82.3231C82.7569 32.5688 83.002 32.0705 82.737 31.7264L77.4102 24.8078L82.406 17.8857C82.6559 17.5399 82.4089 17.0562 81.9828 17.0562Z"
              }
              fill={"#006FFF"}
            />
            <path
              d={
                "M98.1791 21.2574C98.1207 20.6715 97.9333 20.0792 97.6233 19.4974C97.0694 18.4471 96.241 17.6971 95.1605 17.2689C94.108 16.8512 92.8897 16.6398 91.54 16.6398C89.6878 16.6398 88.198 17.0493 87.1128 17.8565C86.0276 18.6638 85.2775 19.7264 84.8835 21.0144C84.8432 21.1459 84.9162 21.2855 85.0475 21.3269L88.4642 22.4111C88.5932 22.452 88.7316 22.3831 88.7765 22.2552C88.9954 21.6319 89.365 21.2113 89.9061 20.9695C90.4729 20.7165 91.0228 20.588 91.54 20.588C92.4676 20.588 93.1278 20.7743 93.5032 21.1418C93.749 21.3824 93.9153 21.7277 94 22.1699C93.4524 22.2516 92.93 22.3293 92.4338 22.4024C91.4601 22.5466 90.5768 22.6991 89.8092 22.8562C89.0316 23.0157 88.3516 23.2015 87.7865 23.4088C86.9535 23.7155 86.2787 24.1046 85.7795 24.5649C85.2758 25.0298 84.9057 25.5748 84.6797 26.1841C84.4573 26.7834 84.3447 27.4535 84.3447 28.1755C84.3447 29.0488 84.549 29.8595 84.9529 30.5839C85.3587 31.3129 85.9617 31.9017 86.7451 32.3352C87.525 32.7663 88.4835 32.9847 89.5955 32.9847C90.9271 32.9847 92.0485 32.7481 92.9282 32.2814C93.4571 32.001 93.969 31.6108 94.4559 31.119V32.997H97.276C97.8212 32.997 98.2631 32.5548 98.2631 32.0092V23.1448C98.2637 22.4543 98.2351 21.8194 98.1791 21.2574ZM90.4017 29.5096C90.0013 29.5096 89.6545 29.4506 89.372 29.3338C89.0999 29.2217 88.8974 29.0669 88.7532 28.8595C88.6107 28.6556 88.5413 28.4138 88.5413 28.1205C88.5413 27.9097 88.5845 27.7245 88.6726 27.5557C88.7614 27.3863 88.9055 27.2251 89.1017 27.0767C89.3095 26.9195 89.5915 26.7694 89.9335 26.6321C90.2464 26.5159 90.6154 26.4067 91.0292 26.3074C91.449 26.2069 92.0094 26.0936 92.6959 25.9697C93.0654 25.9031 93.5009 25.8272 93.9936 25.7431C93.986 25.9224 93.976 26.1152 93.9638 26.319C93.9334 26.8261 93.8272 27.2741 93.648 27.6503C93.5563 27.8413 93.3759 28.0878 93.1109 28.3834C92.85 28.6743 92.491 28.9384 92.0438 29.1685C91.6054 29.3952 91.0526 29.5096 90.4017 29.5096Z"
              }
              fill={"#006FFF"}
            />
            <path
              d={
                "M29.0316 2H20.3161C19.406 2 18.6658 2.7407 18.6658 3.65138V5.36818C18.6658 6.27886 19.406 7.01955 20.3161 7.01955H22.1567L13.5924 15.3021C13.4827 15.4085 13.3391 15.4645 13.1861 15.4616C13.0332 15.4587 12.8913 15.3956 12.7862 15.2852L6.15011 8.29766C5.80628 7.93608 5.34161 7.72987 4.84367 7.71702C4.3428 7.70184 3.86704 7.88526 3.50336 8.23049L2.58162 9.10787C1.83382 9.81936 1.80289 11.0069 2.51332 11.7564L11.938 21.6781C12.2293 21.9842 12.6216 22.1577 13.0437 22.167C13.0548 22.167 13.0664 22.1676 13.0775 22.1676C13.4867 22.1676 13.8732 22.011 14.168 21.7254L25.7835 10.4935V12.0683C25.7835 13.038 26.5716 13.8266 27.5406 13.8266H29.045C30.0134 13.8266 30.8009 13.038 30.8009 12.0683V3.77054C30.8003 2.79444 30.007 2 29.0316 2Z"
              }
              fill={classNames(
                firstGradientId
                  ? "url(#" + firstGradientId + ")"
                  : "url(#gradientOne)"
              )}
            />
          </g>
          <path
            d={
              "M329.52 658.63C511.343 658.63 658.74 511.233 658.74 329.41C658.74 147.587 511.343 0.189941 329.52 0.189941C147.697 0.189941 0.299805 147.587 0.299805 329.41C0.299805 511.233 147.697 658.63 329.52 658.63Z"
            }
            fill={"#E4094D"}
          />
        </g>
        <defs>
          <linearGradient
            // id={"paint0_linear_1135_3343"}
            id={classNames(firstGradientId ? firstGradientId : "gradientOne")}
            x1={"25.3088"}
            y1={"1.46929"}
            x2={"5.88778"}
            y2={"22.7505"}
            gradientUnits={"userSpaceOnUse"}
          >
            <stop stopColor={"#8FFFFF"} />
            <stop offset={"0.151"} stopColor={"#76E5FF"} />
            <stop offset={"0.5535"} stopColor={"#36A6FF"} />
            <stop offset={"0.8476"} stopColor={"#0F7EFF"} />
            <stop offset={"1"} stopColor={"#006FFF"} />
          </linearGradient>
          <clipPath id={"clip0_1135_3343"}>
            <rect width={"101"} height={"35"} fill={"white"} />
          </clipPath>
          <clipPath id={"clip1_1135_3343"}>
            <rect
              width={"96.2632"}
              height={"31"}
              fill={"white"}
              transform={"translate(2 2)"}
            />
          </clipPath>
        </defs>
      </>
    ),
    [Variant.WHITE]: (
      <>
        <path
          d={
            "M18.4253 20.2621V31.3433C18.4253 32.2581 19.1667 33 20.0808 33H21.7323C22.9535 33 23.9435 32.0093 23.9435 30.7872V15.4791C23.9435 14.9774 23.3411 14.7215 22.9803 15.0702L18.991 18.9279C18.629 19.2778 18.4253 19.7592 18.4253 20.2621Z"
          }
          fill={"white"}
        />
        <path
          d={
            "M4.07719 30.7873C4.07719 32.0093 5.06724 33 6.28846 33H7.9399C8.85406 33 9.59543 32.2582 9.59543 31.3434V21.8353C9.59543 21.3598 9.41272 20.9018 9.08523 20.5572L5.05732 16.3157C4.70356 15.9436 4.07719 16.1936 4.07719 16.7077V30.7873Z"
          }
          fill={"white"}
        />
        <path
          d={
            "M38.1539 17.794C37.0144 17.0276 35.6169 16.6392 33.9999 16.6392C32.4798 16.6392 31.1144 16.9896 29.9416 17.6819C28.7677 18.3747 27.8343 19.3589 27.167 20.6084C26.5021 21.8532 26.1653 23.3335 26.1653 25.007C26.1653 26.5457 26.5103 27.9318 27.191 29.127C27.8728 30.3245 28.8296 31.2772 30.0356 31.9595C31.2393 32.6406 32.6479 32.9859 34.2217 32.9859C35.7068 32.9859 37.095 32.5898 38.3477 31.8082C39.6051 31.0237 40.5379 29.9092 41.1205 28.4944C41.1777 28.3559 41.1013 28.1988 40.9577 28.1573L37.1802 27.0732C37.0605 27.0387 36.9338 27.0971 36.8825 27.2104C36.6268 27.773 36.2532 28.2081 35.771 28.5043C35.2859 28.8022 34.6899 28.9535 33.9993 28.9535C32.8937 28.9535 32.0834 28.6095 31.5218 27.9021C31.1564 27.4412 30.9054 26.843 30.7746 26.1204H41.0131C41.1433 26.1204 41.2519 26.0205 41.263 25.8908C41.4229 24.0444 41.2303 22.4117 40.6909 21.0378C40.148 19.6522 39.294 18.561 38.1539 17.794ZM36.8994 22.8106H30.9019C31.0449 22.2896 31.2539 21.8491 31.5248 21.4987C32.0863 20.7737 32.9503 20.4215 34.1668 20.4215C35.1925 20.4215 35.9053 20.7171 36.3454 21.3252C36.6017 21.6792 36.7873 22.1774 36.8994 22.8106Z"
          }
          fill={"white"}
        />
        <path
          d={
            "M46.9517 11.3872H44.0504V33H48.8314V13.2687C48.8314 12.2295 47.9902 11.3872 46.9517 11.3872Z"
          }
          fill={"white"}
        />
        <path
          d={
            "M64.8105 19.4968C64.2565 18.4465 63.4282 17.697 62.3482 17.2683C61.2951 16.8506 60.0774 16.6392 58.7278 16.6392C56.8755 16.6392 55.3858 17.0486 54.3006 17.8559C53.2154 18.6632 52.4652 19.7258 52.0712 21.0138C52.0309 21.1453 52.1039 21.2849 52.2352 21.3263L55.652 22.4105C55.781 22.4514 55.9193 22.3825 55.9643 22.2545C56.1832 21.6313 56.5527 21.2107 57.0938 20.9688C57.6607 20.7159 58.2106 20.5874 58.7278 20.5874C59.6554 20.5874 60.3156 20.7737 60.6915 21.1412C60.9373 21.3818 61.1037 21.7271 61.1883 22.1693C60.6407 22.251 60.1183 22.3287 59.6221 22.4017C58.6484 22.546 57.7652 22.6985 56.9975 22.8556C56.22 23.0151 55.5399 23.2009 54.9748 23.4082C54.1418 23.7149 53.4664 24.1039 52.9679 24.5643C52.4641 25.0292 52.094 25.5742 51.8681 26.1835C51.6457 26.7828 51.533 27.4528 51.533 28.1748C51.533 29.0481 51.7373 29.8589 52.1413 30.5833C52.547 31.3123 53.15 31.9011 53.9334 32.3345C54.7127 32.7656 55.6718 32.9841 56.7839 32.9841C58.1154 32.9841 59.2368 32.7475 60.1165 32.2808C60.6454 32.0004 61.1574 31.6102 61.6442 31.1184V32.9952H64.4643C65.0096 32.9952 65.4515 32.553 65.4515 32.0074V23.1448C65.4515 22.4543 65.4229 21.8194 65.3668 21.2574C65.3079 20.6715 65.1205 20.0792 64.8105 19.4968ZM57.5889 29.5096C57.1884 29.5096 56.8417 29.4506 56.5591 29.3338C56.2871 29.2216 56.0845 29.0668 55.9403 28.8589C55.7979 28.655 55.7284 28.4132 55.7284 28.1199C55.7284 27.9091 55.7716 27.7245 55.8598 27.5551C55.9485 27.3857 56.0927 27.2244 56.2888 27.0761C56.4967 26.9189 56.7786 26.7688 57.1207 26.6315C57.4336 26.5153 57.8025 26.4061 58.217 26.3068C58.6361 26.2063 59.1971 26.093 59.8836 25.9691C60.2525 25.9025 60.688 25.8266 61.1813 25.7425C61.1737 25.9218 61.1638 26.114 61.1515 26.3184C61.1212 26.8255 61.0149 27.2735 60.8351 27.6497C60.7435 27.8407 60.5631 28.0872 60.2981 28.3828C60.0371 28.6737 59.6781 28.9377 59.231 29.1679C58.792 29.3951 58.2398 29.5096 57.5889 29.5096Z"
          }
          fill={"white"}
        />
        <path
          d={
            "M81.9828 17.0562H78.5246C78.0185 17.0562 77.5439 17.3027 77.2526 17.7169L73.6456 22.8451C73.4506 23.1226 73.0146 22.9841 73.0146 22.6453V12.9725C73.0146 12.1015 72.3088 11.3953 71.4384 11.3953H68.2114L68.1852 31.5804C68.184 32.3573 68.8133 32.9876 69.5897 32.9876H73.0152V26.9469C73.0152 26.6099 73.4471 26.4703 73.6439 26.7442L77.3647 31.9209C77.6572 32.3275 78.1271 32.5688 78.6274 32.5688H82.3231C82.7569 32.5688 83.002 32.0705 82.737 31.7264L77.4102 24.8078L82.406 17.8857C82.6559 17.5399 82.4089 17.0562 81.9828 17.0562Z"
          }
          fill={"white"}
        />
        <path
          d={
            "M98.1791 21.2574C98.1207 20.6715 97.9333 20.0792 97.6233 19.4974C97.0694 18.4471 96.241 17.6971 95.1605 17.2689C94.108 16.8512 92.8897 16.6398 91.54 16.6398C89.6878 16.6398 88.198 17.0493 87.1128 17.8565C86.0276 18.6638 85.2775 19.7264 84.8835 21.0144C84.8432 21.1459 84.9162 21.2855 85.0475 21.3269L88.4642 22.4111C88.5932 22.452 88.7316 22.3831 88.7765 22.2552C88.9954 21.6319 89.365 21.2113 89.9061 20.9695C90.4729 20.7165 91.0228 20.588 91.54 20.588C92.4676 20.588 93.1278 20.7743 93.5032 21.1418C93.749 21.3824 93.9153 21.7277 94 22.1699C93.4524 22.2516 92.93 22.3293 92.4338 22.4024C91.4601 22.5466 90.5768 22.6991 89.8092 22.8562C89.0316 23.0157 88.3516 23.2015 87.7865 23.4088C86.9535 23.7155 86.2787 24.1046 85.7795 24.5649C85.2758 25.0298 84.9057 25.5748 84.6797 26.1841C84.4573 26.7834 84.3447 27.4535 84.3447 28.1755C84.3447 29.0488 84.549 29.8595 84.9529 30.5839C85.3587 31.3129 85.9617 31.9017 86.7451 32.3352C87.525 32.7663 88.4835 32.9847 89.5955 32.9847C90.9271 32.9847 92.0485 32.7481 92.9282 32.2814C93.4571 32.001 93.969 31.6108 94.4559 31.119V32.997H97.276C97.8212 32.997 98.2631 32.5548 98.2631 32.0092V23.1448C98.2637 22.4543 98.2351 21.8194 98.1791 21.2574ZM90.4017 29.5096C90.0013 29.5096 89.6545 29.4506 89.372 29.3338C89.0999 29.2217 88.8974 29.0669 88.7532 28.8595C88.6107 28.6556 88.5413 28.4138 88.5413 28.1205C88.5413 27.9097 88.5845 27.7245 88.6726 27.5557C88.7614 27.3863 88.9055 27.2251 89.1017 27.0767C89.3095 26.9195 89.5915 26.7694 89.9335 26.6321C90.2464 26.5159 90.6154 26.4067 91.0292 26.3074C91.449 26.2069 92.0094 26.0936 92.6959 25.9697C93.0654 25.9031 93.5009 25.8272 93.9936 25.7431C93.986 25.9224 93.976 26.1152 93.9638 26.319C93.9334 26.8261 93.8272 27.2741 93.648 27.6503C93.5563 27.8413 93.3759 28.0878 93.1109 28.3834C92.85 28.6743 92.491 28.9384 92.0438 29.1685C91.6054 29.3952 91.0526 29.5096 90.4017 29.5096Z"
          }
          fill={"white"}
        />
        <path
          d={
            "M29.0316 2H20.3161C19.406 2 18.6658 2.7407 18.6658 3.65138V5.36818C18.6658 6.27886 19.406 7.01955 20.3161 7.01955H22.1567L13.5924 15.3021C13.4827 15.4085 13.3391 15.4645 13.1861 15.4616C13.0332 15.4587 12.8913 15.3956 12.7862 15.2852L6.15011 8.29766C5.80628 7.93608 5.34161 7.72987 4.84367 7.71702C4.3428 7.70184 3.86704 7.88526 3.50336 8.23049L2.58162 9.10787C1.83382 9.81936 1.80289 11.0069 2.51332 11.7564L11.938 21.6781C12.2293 21.9842 12.6216 22.1577 13.0437 22.167C13.0548 22.167 13.0664 22.1676 13.0775 22.1676C13.4867 22.1676 13.8732 22.011 14.168 21.7254L25.7835 10.4935V12.0683C25.7835 13.038 26.5716 13.8266 27.5406 13.8266H29.045C30.0134 13.8266 30.8009 13.038 30.8009 12.0683V3.77054C30.8003 2.79444 30.007 2 29.0316 2Z"
          }
          fill={"white"}
        />
      </>
    ),
    [Variant.BLACK]: (
      <>
        <path
          d={
            "M18.4253 20.2621V31.3433C18.4253 32.2581 19.1667 33 20.0808 33H21.7323C22.9535 33 23.9435 32.0093 23.9435 30.7872V15.4791C23.9435 14.9774 23.3411 14.7215 22.9803 15.0702L18.991 18.9279C18.629 19.2778 18.4253 19.7592 18.4253 20.2621Z"
          }
          fill={"black"}
        />
        <path
          d={
            "M4.07719 30.7873C4.07719 32.0093 5.06724 33 6.28846 33H7.9399C8.85406 33 9.59543 32.2582 9.59543 31.3434V21.8353C9.59543 21.3598 9.41272 20.9018 9.08523 20.5572L5.05732 16.3157C4.70356 15.9436 4.07719 16.1936 4.07719 16.7077V30.7873Z"
          }
          fill={"black"}
        />
        <path
          d={
            "M38.1539 17.794C37.0144 17.0276 35.6169 16.6392 33.9999 16.6392C32.4798 16.6392 31.1144 16.9896 29.9416 17.6819C28.7677 18.3747 27.8343 19.3589 27.167 20.6084C26.5021 21.8532 26.1653 23.3335 26.1653 25.007C26.1653 26.5457 26.5103 27.9318 27.191 29.127C27.8728 30.3245 28.8296 31.2772 30.0356 31.9595C31.2393 32.6406 32.6479 32.9859 34.2217 32.9859C35.7068 32.9859 37.095 32.5898 38.3477 31.8082C39.6051 31.0237 40.5379 29.9092 41.1205 28.4944C41.1777 28.3559 41.1013 28.1988 40.9577 28.1573L37.1802 27.0732C37.0605 27.0387 36.9338 27.0971 36.8825 27.2104C36.6268 27.773 36.2532 28.2081 35.771 28.5043C35.2859 28.8022 34.6899 28.9535 33.9993 28.9535C32.8937 28.9535 32.0834 28.6095 31.5218 27.9021C31.1564 27.4412 30.9054 26.843 30.7746 26.1204H41.0131C41.1433 26.1204 41.2519 26.0205 41.263 25.8908C41.4229 24.0444 41.2303 22.4117 40.6909 21.0378C40.148 19.6522 39.294 18.561 38.1539 17.794ZM36.8994 22.8106H30.9019C31.0449 22.2896 31.2539 21.8491 31.5248 21.4987C32.0863 20.7737 32.9503 20.4215 34.1668 20.4215C35.1925 20.4215 35.9053 20.7171 36.3454 21.3252C36.6017 21.6792 36.7873 22.1774 36.8994 22.8106Z"
          }
          fill={"black"}
        />
        <path
          d={
            "M46.9517 11.3872H44.0504V33H48.8314V13.2687C48.8314 12.2295 47.9902 11.3872 46.9517 11.3872Z"
          }
          fill={"black"}
        />
        <path
          d={
            "M64.8105 19.4968C64.2565 18.4465 63.4282 17.697 62.3482 17.2683C61.2951 16.8506 60.0774 16.6392 58.7278 16.6392C56.8755 16.6392 55.3858 17.0486 54.3006 17.8559C53.2154 18.6632 52.4652 19.7258 52.0712 21.0138C52.0309 21.1453 52.1039 21.2849 52.2352 21.3263L55.652 22.4105C55.781 22.4514 55.9193 22.3825 55.9643 22.2545C56.1832 21.6313 56.5527 21.2107 57.0938 20.9688C57.6607 20.7159 58.2106 20.5874 58.7278 20.5874C59.6554 20.5874 60.3156 20.7737 60.6915 21.1412C60.9373 21.3818 61.1037 21.7271 61.1883 22.1693C60.6407 22.251 60.1183 22.3287 59.6221 22.4017C58.6484 22.546 57.7652 22.6985 56.9975 22.8556C56.22 23.0151 55.5399 23.2009 54.9748 23.4082C54.1418 23.7149 53.4664 24.1039 52.9679 24.5643C52.4641 25.0292 52.094 25.5742 51.8681 26.1835C51.6457 26.7828 51.533 27.4528 51.533 28.1748C51.533 29.0481 51.7373 29.8589 52.1413 30.5833C52.547 31.3123 53.15 31.9011 53.9334 32.3345C54.7127 32.7656 55.6718 32.9841 56.7839 32.9841C58.1154 32.9841 59.2368 32.7475 60.1165 32.2808C60.6454 32.0004 61.1574 31.6102 61.6442 31.1184V32.9952H64.4643C65.0096 32.9952 65.4515 32.553 65.4515 32.0074V23.1448C65.4515 22.4543 65.4229 21.8194 65.3668 21.2574C65.3079 20.6715 65.1205 20.0792 64.8105 19.4968ZM57.5889 29.5096C57.1884 29.5096 56.8417 29.4506 56.5591 29.3338C56.2871 29.2216 56.0845 29.0668 55.9403 28.8589C55.7979 28.655 55.7284 28.4132 55.7284 28.1199C55.7284 27.9091 55.7716 27.7245 55.8598 27.5551C55.9485 27.3857 56.0927 27.2244 56.2888 27.0761C56.4967 26.9189 56.7786 26.7688 57.1207 26.6315C57.4336 26.5153 57.8025 26.4061 58.217 26.3068C58.6361 26.2063 59.1971 26.093 59.8836 25.9691C60.2525 25.9025 60.688 25.8266 61.1813 25.7425C61.1737 25.9218 61.1638 26.114 61.1515 26.3184C61.1212 26.8255 61.0149 27.2735 60.8351 27.6497C60.7435 27.8407 60.5631 28.0872 60.2981 28.3828C60.0371 28.6737 59.6781 28.9377 59.231 29.1679C58.792 29.3951 58.2398 29.5096 57.5889 29.5096Z"
          }
          fill={"black"}
        />
        <path
          d={
            "M81.9828 17.0562H78.5246C78.0185 17.0562 77.5439 17.3027 77.2526 17.7169L73.6456 22.8451C73.4506 23.1226 73.0146 22.9841 73.0146 22.6453V12.9725C73.0146 12.1015 72.3088 11.3953 71.4384 11.3953H68.2114L68.1852 31.5804C68.184 32.3573 68.8133 32.9876 69.5897 32.9876H73.0152V26.9469C73.0152 26.6099 73.4471 26.4703 73.6439 26.7442L77.3647 31.9209C77.6572 32.3275 78.1271 32.5688 78.6274 32.5688H82.3231C82.7569 32.5688 83.002 32.0705 82.737 31.7264L77.4102 24.8078L82.406 17.8857C82.6559 17.5399 82.4089 17.0562 81.9828 17.0562Z"
          }
          fill={"black"}
        />
        <path
          d={
            "M98.1791 21.2574C98.1207 20.6715 97.9333 20.0792 97.6233 19.4974C97.0694 18.4471 96.241 17.6971 95.1605 17.2689C94.108 16.8512 92.8897 16.6398 91.54 16.6398C89.6878 16.6398 88.198 17.0493 87.1128 17.8565C86.0276 18.6638 85.2775 19.7264 84.8835 21.0144C84.8432 21.1459 84.9162 21.2855 85.0475 21.3269L88.4642 22.4111C88.5932 22.452 88.7316 22.3831 88.7765 22.2552C88.9954 21.6319 89.365 21.2113 89.9061 20.9695C90.4729 20.7165 91.0228 20.588 91.54 20.588C92.4676 20.588 93.1278 20.7743 93.5032 21.1418C93.749 21.3824 93.9153 21.7277 94 22.1699C93.4524 22.2516 92.93 22.3293 92.4338 22.4024C91.4601 22.5466 90.5768 22.6991 89.8092 22.8562C89.0316 23.0157 88.3516 23.2015 87.7865 23.4088C86.9535 23.7155 86.2787 24.1046 85.7795 24.5649C85.2758 25.0298 84.9057 25.5748 84.6797 26.1841C84.4573 26.7834 84.3447 27.4535 84.3447 28.1755C84.3447 29.0488 84.549 29.8595 84.9529 30.5839C85.3587 31.3129 85.9617 31.9017 86.7451 32.3352C87.525 32.7663 88.4835 32.9847 89.5955 32.9847C90.9271 32.9847 92.0485 32.7481 92.9282 32.2814C93.4571 32.001 93.969 31.6108 94.4559 31.119V32.997H97.276C97.8212 32.997 98.2631 32.5548 98.2631 32.0092V23.1448C98.2637 22.4543 98.2351 21.8194 98.1791 21.2574ZM90.4017 29.5096C90.0013 29.5096 89.6545 29.4506 89.372 29.3338C89.0999 29.2217 88.8974 29.0669 88.7532 28.8595C88.6107 28.6556 88.5413 28.4138 88.5413 28.1205C88.5413 27.9097 88.5845 27.7245 88.6726 27.5557C88.7614 27.3863 88.9055 27.2251 89.1017 27.0767C89.3095 26.9195 89.5915 26.7694 89.9335 26.6321C90.2464 26.5159 90.6154 26.4067 91.0292 26.3074C91.449 26.2069 92.0094 26.0936 92.6959 25.9697C93.0654 25.9031 93.5009 25.8272 93.9936 25.7431C93.986 25.9224 93.976 26.1152 93.9638 26.319C93.9334 26.8261 93.8272 27.2741 93.648 27.6503C93.5563 27.8413 93.3759 28.0878 93.1109 28.3834C92.85 28.6743 92.491 28.9384 92.0438 29.1685C91.6054 29.3952 91.0526 29.5096 90.4017 29.5096Z"
          }
          fill={"black"}
        />
        <path
          d={
            "M29.0316 2H20.3161C19.406 2 18.6658 2.7407 18.6658 3.65138V5.36818C18.6658 6.27886 19.406 7.01955 20.3161 7.01955H22.1567L13.5924 15.3021C13.4827 15.4085 13.3391 15.4645 13.1861 15.4616C13.0332 15.4587 12.8913 15.3956 12.7862 15.2852L6.15011 8.29766C5.80628 7.93608 5.34161 7.72987 4.84367 7.71702C4.3428 7.70184 3.86704 7.88526 3.50336 8.23049L2.58162 9.10787C1.83382 9.81936 1.80289 11.0069 2.51332 11.7564L11.938 21.6781C12.2293 21.9842 12.6216 22.1577 13.0437 22.167C13.0548 22.167 13.0664 22.1676 13.0775 22.1676C13.4867 22.1676 13.8732 22.011 14.168 21.7254L25.7835 10.4935V12.0683C25.7835 13.038 26.5716 13.8266 27.5406 13.8266H29.045C30.0134 13.8266 30.8009 13.038 30.8009 12.0683V3.77054C30.8003 2.79444 30.007 2 29.0316 2Z"
          }
          fill={"black"}
        />
      </>
    ),
    [Variant.BLUE]: (
      <>
        <path
          d={
            "M18.4253 20.2621V31.3433C18.4253 32.2581 19.1667 33 20.0808 33H21.7323C22.9535 33 23.9435 32.0093 23.9435 30.7872V15.4791C23.9435 14.9774 23.3411 14.7215 22.9803 15.0702L18.991 18.9279C18.629 19.2778 18.4253 19.7592 18.4253 20.2621Z"
          }
          fill={"#006FFF"}
        />
        <path
          d={
            "M4.07719 30.7873C4.07719 32.0093 5.06724 33 6.28846 33H7.9399C8.85406 33 9.59543 32.2582 9.59543 31.3434V21.8353C9.59543 21.3598 9.41272 20.9018 9.08523 20.5572L5.05732 16.3157C4.70356 15.9436 4.07719 16.1936 4.07719 16.7077V30.7873Z"
          }
          fill={"#006FFF"}
        />
        <path
          d={
            "M38.1539 17.794C37.0144 17.0276 35.6169 16.6392 33.9999 16.6392C32.4798 16.6392 31.1144 16.9896 29.9416 17.6819C28.7677 18.3747 27.8343 19.3589 27.167 20.6084C26.5021 21.8532 26.1653 23.3335 26.1653 25.007C26.1653 26.5457 26.5103 27.9318 27.191 29.127C27.8728 30.3245 28.8296 31.2772 30.0356 31.9595C31.2393 32.6406 32.6479 32.9859 34.2217 32.9859C35.7068 32.9859 37.095 32.5898 38.3477 31.8082C39.6051 31.0237 40.5379 29.9092 41.1205 28.4944C41.1777 28.3559 41.1013 28.1988 40.9577 28.1573L37.1802 27.0732C37.0605 27.0387 36.9338 27.0971 36.8825 27.2104C36.6268 27.773 36.2532 28.2081 35.771 28.5043C35.2859 28.8022 34.6899 28.9535 33.9993 28.9535C32.8937 28.9535 32.0834 28.6095 31.5218 27.9021C31.1564 27.4412 30.9054 26.843 30.7746 26.1204H41.0131C41.1433 26.1204 41.2519 26.0205 41.263 25.8908C41.4229 24.0444 41.2303 22.4117 40.6909 21.0378C40.148 19.6522 39.294 18.561 38.1539 17.794ZM36.8994 22.8106H30.9019C31.0449 22.2896 31.2539 21.8491 31.5248 21.4987C32.0863 20.7737 32.9503 20.4215 34.1668 20.4215C35.1925 20.4215 35.9053 20.7171 36.3454 21.3252C36.6017 21.6792 36.7873 22.1774 36.8994 22.8106Z"
          }
          fill={"#006FFF"}
        />
        <path
          d={
            "M46.9517 11.3872H44.0504V33H48.8314V13.2687C48.8314 12.2295 47.9902 11.3872 46.9517 11.3872Z"
          }
          fill={"#006FFF"}
        />
        <path
          d={
            "M64.8105 19.4968C64.2565 18.4465 63.4282 17.697 62.3482 17.2683C61.2951 16.8506 60.0774 16.6392 58.7278 16.6392C56.8755 16.6392 55.3858 17.0486 54.3006 17.8559C53.2154 18.6632 52.4652 19.7258 52.0712 21.0138C52.0309 21.1453 52.1039 21.2849 52.2352 21.3263L55.652 22.4105C55.781 22.4514 55.9193 22.3825 55.9643 22.2545C56.1832 21.6313 56.5527 21.2107 57.0938 20.9688C57.6607 20.7159 58.2106 20.5874 58.7278 20.5874C59.6554 20.5874 60.3156 20.7737 60.6915 21.1412C60.9373 21.3818 61.1037 21.7271 61.1883 22.1693C60.6407 22.251 60.1183 22.3287 59.6221 22.4017C58.6484 22.546 57.7652 22.6985 56.9975 22.8556C56.22 23.0151 55.5399 23.2009 54.9748 23.4082C54.1418 23.7149 53.4664 24.1039 52.9679 24.5643C52.4641 25.0292 52.094 25.5742 51.8681 26.1835C51.6457 26.7828 51.533 27.4528 51.533 28.1748C51.533 29.0481 51.7373 29.8589 52.1413 30.5833C52.547 31.3123 53.15 31.9011 53.9334 32.3345C54.7127 32.7656 55.6718 32.9841 56.7839 32.9841C58.1154 32.9841 59.2368 32.7475 60.1165 32.2808C60.6454 32.0004 61.1574 31.6102 61.6442 31.1184V32.9952H64.4643C65.0096 32.9952 65.4515 32.553 65.4515 32.0074V23.1448C65.4515 22.4543 65.4229 21.8194 65.3668 21.2574C65.3079 20.6715 65.1205 20.0792 64.8105 19.4968ZM57.5889 29.5096C57.1884 29.5096 56.8417 29.4506 56.5591 29.3338C56.2871 29.2216 56.0845 29.0668 55.9403 28.8589C55.7979 28.655 55.7284 28.4132 55.7284 28.1199C55.7284 27.9091 55.7716 27.7245 55.8598 27.5551C55.9485 27.3857 56.0927 27.2244 56.2888 27.0761C56.4967 26.9189 56.7786 26.7688 57.1207 26.6315C57.4336 26.5153 57.8025 26.4061 58.217 26.3068C58.6361 26.2063 59.1971 26.093 59.8836 25.9691C60.2525 25.9025 60.688 25.8266 61.1813 25.7425C61.1737 25.9218 61.1638 26.114 61.1515 26.3184C61.1212 26.8255 61.0149 27.2735 60.8351 27.6497C60.7435 27.8407 60.5631 28.0872 60.2981 28.3828C60.0371 28.6737 59.6781 28.9377 59.231 29.1679C58.792 29.3951 58.2398 29.5096 57.5889 29.5096Z"
          }
          fill={"#006FFF"}
        />
        <path
          d={
            "M81.9828 17.0562H78.5246C78.0185 17.0562 77.5439 17.3027 77.2526 17.7169L73.6456 22.8451C73.4506 23.1226 73.0146 22.9841 73.0146 22.6453V12.9725C73.0146 12.1015 72.3088 11.3953 71.4384 11.3953H68.2114L68.1852 31.5804C68.184 32.3573 68.8133 32.9876 69.5897 32.9876H73.0152V26.9469C73.0152 26.6099 73.4471 26.4703 73.6439 26.7442L77.3647 31.9209C77.6572 32.3275 78.1271 32.5688 78.6274 32.5688H82.3231C82.7569 32.5688 83.002 32.0705 82.737 31.7264L77.4102 24.8078L82.406 17.8857C82.6559 17.5399 82.4089 17.0562 81.9828 17.0562Z"
          }
          fill={"#006FFF"}
        />
        <path
          d={
            "M98.1791 21.2574C98.1207 20.6715 97.9333 20.0792 97.6233 19.4974C97.0694 18.4471 96.241 17.6971 95.1605 17.2689C94.108 16.8512 92.8897 16.6398 91.54 16.6398C89.6878 16.6398 88.198 17.0493 87.1128 17.8565C86.0276 18.6638 85.2775 19.7264 84.8835 21.0144C84.8432 21.1459 84.9162 21.2855 85.0475 21.3269L88.4642 22.4111C88.5932 22.452 88.7316 22.3831 88.7765 22.2552C88.9954 21.6319 89.365 21.2113 89.9061 20.9695C90.4729 20.7165 91.0228 20.588 91.54 20.588C92.4676 20.588 93.1278 20.7743 93.5032 21.1418C93.749 21.3824 93.9153 21.7277 94 22.1699C93.4524 22.2516 92.93 22.3293 92.4338 22.4024C91.4601 22.5466 90.5768 22.6991 89.8092 22.8562C89.0316 23.0157 88.3516 23.2015 87.7865 23.4088C86.9535 23.7155 86.2787 24.1046 85.7795 24.5649C85.2758 25.0298 84.9057 25.5748 84.6797 26.1841C84.4573 26.7834 84.3447 27.4535 84.3447 28.1755C84.3447 29.0488 84.549 29.8595 84.9529 30.5839C85.3587 31.3129 85.9617 31.9017 86.7451 32.3352C87.525 32.7663 88.4835 32.9847 89.5955 32.9847C90.9271 32.9847 92.0485 32.7481 92.9282 32.2814C93.4571 32.001 93.969 31.6108 94.4559 31.119V32.997H97.276C97.8212 32.997 98.2631 32.5548 98.2631 32.0092V23.1448C98.2637 22.4543 98.2351 21.8194 98.1791 21.2574ZM90.4017 29.5096C90.0013 29.5096 89.6545 29.4506 89.372 29.3338C89.0999 29.2217 88.8974 29.0669 88.7532 28.8595C88.6107 28.6556 88.5413 28.4138 88.5413 28.1205C88.5413 27.9097 88.5845 27.7245 88.6726 27.5557C88.7614 27.3863 88.9055 27.2251 89.1017 27.0767C89.3095 26.9195 89.5915 26.7694 89.9335 26.6321C90.2464 26.5159 90.6154 26.4067 91.0292 26.3074C91.449 26.2069 92.0094 26.0936 92.6959 25.9697C93.0654 25.9031 93.5009 25.8272 93.9936 25.7431C93.986 25.9224 93.976 26.1152 93.9638 26.319C93.9334 26.8261 93.8272 27.2741 93.648 27.6503C93.5563 27.8413 93.3759 28.0878 93.1109 28.3834C92.85 28.6743 92.491 28.9384 92.0438 29.1685C91.6054 29.3952 91.0526 29.5096 90.4017 29.5096Z"
          }
          fill={"#006FFF"}
        />
        <path
          d={
            "M29.0316 2H20.3161C19.406 2 18.6658 2.7407 18.6658 3.65138V5.36818C18.6658 6.27886 19.406 7.01955 20.3161 7.01955H22.1567L13.5924 15.3021C13.4827 15.4085 13.3391 15.4645 13.1861 15.4616C13.0332 15.4587 12.8913 15.3956 12.7862 15.2852L6.15011 8.29766C5.80628 7.93608 5.34161 7.72987 4.84367 7.71702C4.3428 7.70184 3.86704 7.88526 3.50336 8.23049L2.58162 9.10787C1.83382 9.81936 1.80289 11.0069 2.51332 11.7564L11.938 21.6781C12.2293 21.9842 12.6216 22.1577 13.0437 22.167C13.0548 22.167 13.0664 22.1676 13.0775 22.1676C13.4867 22.1676 13.8732 22.011 14.168 21.7254L25.7835 10.4935V12.0683C25.7835 13.038 26.5716 13.8266 27.5406 13.8266H29.045C30.0134 13.8266 30.8009 13.038 30.8009 12.0683V3.77054C30.8003 2.79444 30.007 2 29.0316 2Z"
          }
          fill={"#006FFF"}
        />
      </>
    ),
    [Variant.POS]: [
      <>
        <g clipPath={"url(#clip0_306_26369)"}>
          <path
            d={
              "M233.636 69.8897C232.462 69.8897 231.553 69.577 230.909 68.9517C230.303 68.2897 230 67.3885 230 66.2483V34.1379C230 32.9609 230.322 32.0782 230.966 31.4897C231.61 30.8644 232.519 30.5517 233.693 30.5517H247.386C251.78 30.5517 255.17 31.6184 257.557 33.7517C259.943 35.8851 261.136 38.8644 261.136 42.6897C261.136 46.5149 259.943 49.5126 257.557 51.6828C255.17 53.8161 251.78 54.8828 247.386 54.8828H237.273V66.2483C237.273 67.3885 236.97 68.2897 236.364 68.9517C235.758 69.577 234.848 69.8897 233.636 69.8897ZM237.273 49.3103H246.25C248.826 49.3103 250.776 48.7586 252.102 47.6552C253.428 46.5149 254.091 44.8598 254.091 42.6897C254.091 40.5195 253.428 38.8828 252.102 37.7793C250.776 36.6759 248.826 36.1241 246.25 36.1241H237.273V49.3103Z"
            }
            fill={"#1D65ED"}
          />
          <path
            d={
              "M284.742 70C280.878 70 277.488 69.1724 274.571 67.5172C271.692 65.8621 269.458 63.5448 267.867 60.5655C266.276 57.5494 265.48 54.0184 265.48 49.9724C265.48 46.9195 265.935 44.1793 266.844 41.7517C267.753 39.2874 269.041 37.1908 270.708 35.4621C272.412 33.6966 274.439 32.354 276.787 31.4345C279.173 30.4782 281.825 30 284.742 30C288.643 30 292.033 30.8276 294.912 32.4828C297.791 34.1011 300.026 36.4 301.616 39.3793C303.207 42.3586 304.003 45.8713 304.003 49.9172C304.003 52.9701 303.548 55.7287 302.639 58.1931C301.73 60.6575 300.423 62.7724 298.719 64.5379C297.052 66.3034 295.026 67.6644 292.639 68.6207C290.291 69.5402 287.658 70 284.742 70ZM284.742 63.931C287.204 63.931 289.287 63.3793 290.992 62.2759C292.734 61.1724 294.06 59.5724 294.969 57.4759C295.916 55.3793 296.389 52.8782 296.389 49.9724C296.389 45.5586 295.367 42.1379 293.321 39.7103C291.314 37.2828 288.454 36.069 284.742 36.069C282.317 36.069 280.234 36.6207 278.492 37.7241C276.749 38.7908 275.405 40.3724 274.458 42.469C273.549 44.5287 273.094 47.0299 273.094 49.9724C273.094 54.3494 274.117 57.7701 276.162 60.2345C278.208 62.6988 281.067 63.931 284.742 63.931Z"
            }
            fill={"#1D65ED"}
          />
          <path
            d={
              "M325.205 70C323.538 70 321.871 69.8713 320.205 69.6138C318.538 69.3931 316.966 69.0437 315.489 68.5655C314.012 68.0506 312.686 67.4253 311.512 66.6897C310.83 66.2483 310.356 65.7149 310.091 65.0897C309.826 64.4644 309.731 63.8575 309.807 63.269C309.921 62.6437 310.167 62.1103 310.546 61.669C310.962 61.1908 311.474 60.8966 312.08 60.7862C312.686 60.6759 313.349 60.8414 314.068 61.2828C315.735 62.2391 317.496 62.9379 319.352 63.3793C321.208 63.8207 323.159 64.0414 325.205 64.0414C328.197 64.0414 330.375 63.5632 331.739 62.6069C333.102 61.6138 333.784 60.3448 333.784 58.8C333.784 57.5126 333.292 56.5011 332.307 55.7655C331.36 55.0299 329.712 54.423 327.364 53.9448L321.114 52.6759C317.515 51.9402 314.826 50.708 313.046 48.9793C311.303 47.2138 310.432 44.8966 310.432 42.0276C310.432 40.2253 310.811 38.5885 311.568 37.1172C312.326 35.646 313.387 34.377 314.75 33.3103C316.152 32.2437 317.799 31.4345 319.693 30.8828C321.625 30.2943 323.746 30 326.057 30C328.33 30 330.489 30.2759 332.534 30.8276C334.58 31.3793 336.417 32.1885 338.045 33.2552C338.652 33.6598 339.049 34.1563 339.239 34.7448C339.466 35.3333 339.523 35.9218 339.409 36.5103C339.295 37.0621 339.03 37.5402 338.614 37.9448C338.197 38.3494 337.667 38.5885 337.023 38.6621C336.417 38.7356 335.716 38.5517 334.921 38.1103C333.519 37.3379 332.099 36.7862 330.659 36.4552C329.22 36.1241 327.667 35.9586 326 35.9586C324.258 35.9586 322.762 36.1977 321.512 36.6759C320.262 37.154 319.296 37.8345 318.614 38.7172C317.97 39.5632 317.648 40.5747 317.648 41.7517C317.648 43.0759 318.102 44.1425 319.012 44.9517C319.921 45.7241 321.474 46.331 323.671 46.7724L329.864 48.0414C333.614 48.8138 336.398 50.0276 338.216 51.6828C340.072 53.3379 341 55.5448 341 58.3034C341 60.069 340.621 61.669 339.864 63.1034C339.144 64.5379 338.083 65.7701 336.682 66.8C335.318 67.8299 333.671 68.6207 331.739 69.1724C329.807 69.7241 327.629 70 325.205 70Z"
            }
            fill={"#1D65ED"}
          />
          <path
            d={
              "M37.3228 41.2371V66.2592C37.3228 68.3248 39.0073 70 41.0846 70H44.8371C47.6121 70 49.8617 67.7629 49.8617 65.0035V30.4368C49.8617 29.3038 48.4928 28.726 47.6731 29.5135L38.6081 38.2244C37.7857 39.0145 37.3228 40.1014 37.3228 41.2371Z"
            }
            fill={"#006FFF"}
          />
          <path
            d={
              "M4.71973 65.0036C4.71973 67.763 6.96939 70.0001 9.74433 70.0001H13.4969C15.5741 70.0001 17.2587 68.3249 17.2587 66.2593V44.7894C17.2587 43.7157 16.8435 42.6815 16.0994 41.9033L6.94684 32.3258C6.14301 31.4855 4.71973 32.0501 4.71973 33.2108V65.0036Z"
            }
            fill={"#006FFF"}
          />
          <path
            d={
              "M82.152 35.6644C79.5628 33.9338 76.3872 33.0566 72.713 33.0566C69.2589 33.0566 66.1563 33.8481 63.4915 35.4111C60.824 36.9755 58.703 39.1981 57.1868 42.0195C55.676 44.8304 54.9106 48.1728 54.9106 51.9518C54.9106 55.4262 55.6946 58.5563 57.2412 61.255C58.7905 63.959 60.9646 66.1104 63.705 67.651C66.4402 69.189 69.6409 69.9686 73.217 69.9686C76.5915 69.9686 79.7458 69.0743 82.5924 67.3094C85.4496 65.5379 87.5692 63.0212 88.893 59.8265C89.023 59.5139 88.8493 59.1591 88.523 59.0654L79.9395 56.6173C79.6676 56.5394 79.3797 56.6714 79.263 56.9272C78.682 58.1975 77.8331 59.1802 76.7374 59.8489C75.6351 60.5216 74.2808 60.8633 72.7116 60.8633C70.1993 60.8633 68.3582 60.0863 67.0822 58.489C66.2518 57.4483 65.6814 56.0976 65.3843 54.4659H88.649C88.9448 54.4659 89.1915 54.2404 89.2167 53.9475C89.5802 49.7781 89.1424 46.0914 87.9168 42.989C86.6832 39.8602 84.7426 37.3963 82.152 35.6644ZM79.3015 46.9923H65.6735C65.9985 45.8157 66.4733 44.8211 67.0888 44.0297C68.3649 42.3928 70.328 41.5974 73.0923 41.5974C75.4229 41.5974 77.0425 42.2648 78.0427 43.6379C78.625 44.4373 79.0468 45.5624 79.3015 46.9923Z"
            }
            fill={"#006FFF"}
          />
          <path
            d={
              "M102.143 21.1973H95.5503V70.0003H106.414V25.4459C106.414 23.0993 104.503 21.1973 102.143 21.1973Z"
            }
            fill={"#006FFF"}
          />
          <path
            d={
              "M142.723 39.5094C141.464 37.1377 139.582 35.4454 137.128 34.4772C134.735 33.5341 131.968 33.0566 128.901 33.0566C124.692 33.0566 121.307 33.9813 118.841 35.8042C116.376 37.6271 114.671 40.0264 113.776 42.9349C113.684 43.2317 113.85 43.5469 114.148 43.6406L121.912 46.0887C122.205 46.1811 122.52 46.0254 122.622 45.7365C123.119 44.3291 123.959 43.3794 125.189 42.8333C126.477 42.2622 127.726 41.972 128.901 41.972C131.009 41.972 132.509 42.3928 133.363 43.2225C133.922 43.7659 134.3 44.5454 134.492 45.544C133.248 45.7286 132.061 45.9041 130.933 46.0689C128.721 46.3947 126.714 46.739 124.97 47.0938C123.203 47.4539 121.658 47.8734 120.373 48.3416C118.481 49.0341 116.946 49.9126 115.813 50.952C114.668 52.002 113.827 53.2326 113.314 54.6084C112.809 55.9617 112.553 57.4746 112.553 59.105C112.553 61.0769 113.017 62.9078 113.935 64.5434C114.857 66.1895 116.227 67.5191 118.007 68.4978C119.778 69.4713 121.957 69.9646 124.484 69.9646C127.51 69.9646 130.058 69.4304 132.057 68.3765C133.259 67.7433 134.422 66.8622 135.528 65.7516V69.9897H141.936C143.175 69.9897 144.179 68.9911 144.179 67.7592V47.7467C144.179 46.1876 144.114 44.7539 143.987 43.4849C143.853 42.1619 143.427 40.8244 142.723 39.5094ZM126.313 62.119C125.403 62.119 124.615 61.9858 123.973 61.7219C123.355 61.4687 122.895 61.1191 122.567 60.6496C122.244 60.1892 122.086 59.6431 122.086 58.981C122.086 58.5048 122.184 58.088 122.384 57.7055C122.586 57.323 122.914 56.9589 123.359 56.6239C123.832 56.269 124.472 55.9301 125.25 55.6201C125.961 55.3576 126.799 55.1109 127.741 54.8867C128.693 54.6598 129.968 54.4039 131.528 54.1243C132.366 53.9739 133.356 53.8024 134.476 53.6125C134.459 54.0174 134.437 54.4514 134.409 54.9131C134.34 56.058 134.098 57.0697 133.69 57.9192C133.482 58.3505 133.072 58.9071 132.469 59.5745C131.877 60.2314 131.061 60.8276 130.045 61.3473C129.047 61.8604 127.792 62.119 126.313 62.119Z"
            }
            fill={"#006FFF"}
          />
          <path
            d={
              "M181.743 33.9976H173.885C172.735 33.9976 171.656 34.5543 170.994 35.4895L162.798 47.0693C162.355 47.6958 161.364 47.3832 161.364 46.6182V24.7762C161.364 22.8096 159.761 21.2148 157.783 21.2148H150.45L150.391 66.7941C150.388 68.5485 151.818 69.9717 153.582 69.9717H161.366V56.3315C161.366 55.5705 162.347 55.2552 162.794 55.8738L171.249 67.5631C171.914 68.4812 172.981 69.026 174.118 69.026H182.516C183.502 69.026 184.059 67.9008 183.456 67.1239L171.353 51.5012L182.704 35.8707C183.272 35.0898 182.711 33.9976 181.743 33.9976Z"
            }
            fill={"#006FFF"}
          />
          <path
            d={
              "M218.546 43.4846C218.413 42.1616 217.988 40.8241 217.283 39.5103C216.024 37.1387 214.142 35.4451 211.687 34.4782C209.295 33.5351 206.527 33.0576 203.46 33.0576C199.251 33.0576 195.866 33.9823 193.4 35.8052C190.935 37.6281 189.23 40.0274 188.335 42.9359C188.243 43.2327 188.409 43.5479 188.707 43.6416L196.471 46.0897C196.764 46.182 197.079 46.0264 197.181 45.7375C197.678 44.3301 198.518 43.3804 199.747 42.8343C201.035 42.2632 202.285 41.973 203.46 41.973C205.568 41.973 207.068 42.3938 207.921 43.2234C208.48 43.7669 208.858 44.5464 209.05 45.5449C207.806 45.7296 206.619 45.905 205.491 46.0699C203.278 46.3957 201.272 46.74 199.527 47.0948C197.76 47.4549 196.215 47.8744 194.931 48.3426C193.038 49.0351 191.505 49.9136 190.371 50.953C189.226 52.0029 188.385 53.2336 187.872 54.6093C187.366 55.9627 187.11 57.4756 187.11 59.1059C187.11 61.0779 187.575 62.9087 188.493 64.5443C189.414 66.1905 190.785 67.5201 192.565 68.4988C194.337 69.4723 196.515 69.9656 199.042 69.9656C202.067 69.9656 204.616 69.4314 206.615 68.3775C207.816 67.7443 208.98 66.8632 210.086 65.7526V69.9933H216.494C217.733 69.9933 218.737 68.9948 218.737 67.7628V47.7464C218.738 46.1873 218.673 44.7535 218.546 43.4846ZM200.874 62.1186C199.964 62.1186 199.176 61.9854 198.534 61.7216C197.916 61.4683 197.455 61.1188 197.128 60.6505C196.804 60.1902 196.646 59.6441 196.646 58.982C196.646 58.5058 196.744 58.0877 196.945 57.7065C197.146 57.3239 197.474 56.9599 197.92 56.6248C198.392 56.27 199.033 55.931 199.81 55.6211C200.521 55.3586 201.359 55.1119 202.3 54.8877C203.253 54.6608 204.527 54.4049 206.087 54.1253C206.926 53.9749 207.916 53.8034 209.035 53.6135C209.018 54.0184 208.996 54.4537 208.968 54.914C208.899 56.059 208.657 57.0707 208.25 57.9201C208.042 58.3515 207.632 58.9081 207.03 59.5755C206.437 60.2324 205.621 60.8286 204.605 61.3483C203.609 61.8601 202.353 62.1186 200.874 62.1186Z"
            }
            fill={"#006FFF"}
          />
          <path
            d={
              "M61.4232 0H41.6192C39.5513 0 37.8694 1.67254 37.8694 3.72892V7.60557C37.8694 9.66194 39.5513 11.3345 41.6192 11.3345H45.8015L26.3412 30.0371C26.0918 30.2772 25.7655 30.4038 25.4179 30.3972C25.0704 30.3906 24.7481 30.2481 24.5093 29.9988L9.4302 14.2205C8.64892 13.404 7.59306 12.9384 6.4616 12.9094C5.3235 12.8751 4.24244 13.2893 3.41606 14.0688L1.32159 16.05C-0.377596 17.6566 -0.447897 20.3382 1.1664 22.0306L22.582 44.4344C23.2439 45.1256 24.1353 45.5173 25.0943 45.5384C25.1195 45.5384 25.146 45.5397 25.1712 45.5397C26.1011 45.5397 26.9792 45.1862 27.649 44.5412L54.0428 19.1788V22.7349C54.0428 24.9245 55.8335 26.7052 58.0354 26.7052H61.4537C63.6543 26.7052 65.4437 24.9245 65.4437 22.7349V3.998C65.4423 1.79389 63.6397 0 61.4232 0Z"
            }
            fill={classNames(
              thirdGradientId
                ? "url(#" + thirdGradientId + ")"
                : "url(#gradientThree)"
            )}
          />
        </g>
        <defs>
          <linearGradient
            id={classNames(thirdGradientId ? thirdGradientId : "gradientThree")}
            x1={"52.964"}
            y1={"-1.19839"}
            x2={"9.13607"}
            y2={"47.1299"}
            gradientUnits={"userSpaceOnUse"}
          >
            <stop stopColor={"#8FFFFF"} />
            <stop offset={"0.151"} stopColor={"#76E5FF"} />
            <stop offset={"0.5535"} stopColor={"#36A6FF"} />
            <stop offset={"0.8476"} stopColor={"#0F7EFF"} />
            <stop offset={"1"} stopColor={"#006FFF"} />
          </linearGradient>
        </defs>
      </>,
    ],
    [Variant.CHAT]: [
      <>
        <path
          d={
            "M8.84366 9.609V14.226C8.84366 14.6072 9.15256 14.9163 9.53344 14.9163H10.2215C10.7303 14.9163 11.1429 14.5035 11.1429 13.9943V7.61616C11.1429 7.40709 10.8918 7.30049 10.7415 7.44579L9.07935 9.05311C8.92855 9.1989 8.84366 9.39945 8.84366 9.609Z"
          }
          fill={"white"}
        />
        <path
          d={
            "M2.86547 13.9943C2.86547 14.5035 3.27798 14.9163 3.7868 14.9163H4.47488C4.85577 14.9163 5.16466 14.6072 5.16466 14.226V10.2644C5.16466 10.0663 5.08853 9.87549 4.95209 9.73189L3.27384 7.96467C3.12645 7.80963 2.86547 7.9138 2.86547 8.12798V13.9943Z"
          }
          fill={"white"}
        />
        <path
          d={
            "M13.2628 2H9.63146C9.25228 2 8.94387 2.30861 8.94387 2.68805V3.40336C8.94387 3.7828 9.25228 4.09141 9.63146 4.09141H10.3983L6.83001 7.54238C6.78429 7.58668 6.72446 7.61004 6.66073 7.60883C6.59701 7.60761 6.5379 7.58132 6.49412 7.53532L3.72916 4.62394C3.5859 4.47329 3.39229 4.38737 3.18482 4.38202C2.97614 4.37569 2.77791 4.45211 2.62638 4.59595L2.24233 4.96152C1.93076 5.25796 1.91787 5.75277 2.21387 6.06503L6.14072 10.1989C6.26209 10.3265 6.42553 10.3988 6.60138 10.4027C6.60601 10.4027 6.61087 10.4029 6.61549 10.4029C6.78599 10.4029 6.94701 10.3377 7.06983 10.2187L11.9095 5.53883V6.195C11.9095 6.59902 12.2378 6.92759 12.6416 6.92759H13.2684C13.6719 6.92759 14 6.59902 14 6.195V2.7377C13.9998 2.331 13.6692 2 13.2628 2Z"
          }
          fill={"white"}
        />
      </>,
    ],
  };
  return (
    <svg
      className={className}
      xmlns={"http://www.w3.org/2000/svg"}
      width={"101"}
      height={"35"}
      viewBox={
        variantSVG[variant] === variantSVG[Variant.POS]
          ? "0 0 341 70"
          : variantSVG[variant] === variantSVG[Variant.CHAT]
          ? "0 0 16 16"
          : "0 0 101 35"
      }
      fill={"none"}
    >
      {variantSVG[variant]}
    </svg>
  );
};

export default Logo;
