"use client";

import { FlagKeys } from "@saas/flags/utils";

import { useFeature } from "flagged";

export type UseFlagStatusReturnType = boolean;

export const useFlagStatus = (key: FlagKeys): UseFlagStatusReturnType => {
  return useFeature(key) as UseFlagStatusReturnType;
};

export default useFlagStatus;
