// TODO: Refactor Cashier into `store`
// eslint-disable-next-line @nx/enforce-module-boundaries
import { StorePromoModel } from "@saas/promotion/utils";

import {
  CartItemInterface,
  getOfflineOrderPromo,
  OrderProductInterface,
} from "../../";

import { groupBy } from "lodash-es";

export type GetOfflineProductsInput = ReadonlyArray<CartItemInterface>;

export type GetOfflineProductsOutput = ReadonlyArray<OrderProductInterface>;

export const getOfflineProducts = (
  productVariants: GetOfflineProductsInput,
  promos?: ReadonlyArray<StorePromoModel>
): GetOfflineProductsOutput => {
  const { discountProducts } = getOfflineOrderPromo({ promos });

  return Object.values(groupBy(productVariants, "productId")).map(
    (variants, i) => ({
      imageUrl: "/images/product-image-placeholder.png",
      name: variants[0].productName ?? "",
      sku: "",
      productId: i,
      masterProductId: i,
      variants: variants.map((variant) => {
        const discountProductAmount =
          (discountProducts.find(
            (products) =>
              products?.masterProductVariantId ===
              variant.masterProductVariantId
          )?.amount ?? 0) / variant.quantity;

        const discountedPrice = variant.price - (discountProductAmount ?? 0);

        return {
          name: variant.name,
          sku: variant.sku,
          imageUrl: "/images/product-image-placeholder.png",
          originalPrice: variant.price,
          discountedPrice: discountedPrice,
          quantity: variant.quantity,
          returnedQuantity: 0,
          outOfStock: false,
          notFound: false,
          masterProductVariantId: variant.masterProductVariantId,
          productVariantId: variant.id ?? 0,
          isProblematicStock: false,
        };
      }),
    })
  );
};

export default getOfflineProducts;
