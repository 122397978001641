import { dayjs } from "@saas/core";

export type FormatPromotionDatetimeInput = string | Date;

export type FormatPromotionDatetimeOutput = string;

export const formatReadableDate = (
  input?: FormatPromotionDatetimeInput,
  fullMonth?: boolean
): FormatPromotionDatetimeOutput => {
  if (!input || input.toString() === "Invalid Date") return "-";

  return dayjs(input).format(`D ${fullMonth ? "MMMM" : "MMM"} YYYY`);
};

export default formatReadableDate;
