"use client";

import {
  ChatRoomModel,
  crmChannelDisplayConfig,
  LastMessage,
} from "@saas/crm/utils";
import { CounterBubble } from "@saas/shared/ui";
import { classNames, formatReadableTimeLocal } from "@saas/shared/utils";

export interface ChatRoomPreviewProps {
  data: ChatRoomModel;
  isActive: boolean;
  testid: string;
  showUnreadCount?: boolean;
  onOpenChatRoom: (data: ChatRoomModel) => void;
}

export const ChatRoomPreview = ({
  data,
  testid,
  isActive,
  showUnreadCount,
  onOpenChatRoom,
}: ChatRoomPreviewProps) => {
  const isUnreadCountShow = showUnreadCount && data.totalUnreadCount > 0;

  return (
    <div
      aria-label={"chat-room-preview"}
      className={classNames(
        "flex items-center gap-2 py-3 px-4",
        isActive ? "bg-blue-B100" : "bg-white"
      )}
      onClick={() => onOpenChatRoom(data)}
    >
      <div
        className={
          "relative h-10 w-10 flex-shrink-0 overflow-hidden rounded-sm"
        }
      >
        <img
          src={data.chatBuyerAvatar ?? "/images/product-image-placeholder.png"}
          className={"h-full w-full"}
          alt={"customer profile"}
          data-testid={`${testid}__image__customer-profile`}
        />
      </div>

      <div className={"w-full"}>
        <div className={"mb-0.5 flex items-center justify-between gap-1"}>
          <h4
            className={"body-b1 text-he max-w-[148px] truncate font-semibold"}
            data-testid={`${testid}__text__customer-name`}
          >
            {data.chatBuyerName}
          </h4>

          <span
            className={
              "body-b2 text-le max-w-[54px] truncate border-l-[1px] pl-1"
            }
            data-testid={`${testid}-list__text__shop-name`}
          >
            {data.shopName}
          </span>
        </div>

        <div className={"flex items-center justify-between gap-0.5"}>
          <div
            className={classNames(
              isUnreadCountShow ? "max-w-[122px]" : "max-w-[144px]"
            )}
          >
            <ChatRoomPreviewContent
              message={data.lastMessage}
              testid={testid}
            />
          </div>

          <div className={"flex gap-0.5"}>
            {isUnreadCountShow ? (
              <CounterBubble
                counter={data.totalUnreadCount}
                testid={`${testid}__icon__unread-count`}
                isShowCounter={
                  crmChannelDisplayConfig(data.channel).isCounterBubbleVisible
                }
              />
            ) : null}

            <span
              className={classNames(
                "body-b3  min-w-[64px]",
                isUnreadCountShow ? "text-blue-B700" : "text-he"
              )}
              data-testid={`${testid}__text__date`}
            >
              {formatReadableTimeLocal(data.lastUpdatedAt)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ChatRoomPreviewContent = ({
  message,
  testid,
}: {
  message: LastMessage;
  testid: string;
}) => {
  switch (true) {
    case !!message.content.imageUrl:
      return (
        <p
          className={"body-b2 text-he truncate"}
          data-testid={`${testid}__image__message`}
        >
          [Gambar]
        </p>
      );
    case !!message.content.product:
      return (
        <p
          className={"body-b2 text-he truncate"}
          data-testid={`${testid}__product__message`}
        >
          [Produk]
        </p>
      );
    case !!message.content.order:
      return (
        <p
          className={"body-b2 text-he truncate"}
          data-testid={`${testid}__order__message`}
        >
          [Order]
        </p>
      );
    case !!message.content.text:
      return (
        <p
          className={classNames(
            "body-b2 text-he truncate",
            message.isSystem ? "italic" : ""
          )}
          data-testid={`${testid}__text__message`}
        >
          {message.content.text}
        </p>
      );
    default:
      return (
        <p
          className={"body-b2 text-he truncate italic"}
          data-testid={`${testid}__text__message`}
        >
          Pesan belum didukung. Harap cek Seller Center.
        </p>
      );
  }
};

export default ChatRoomPreview;
