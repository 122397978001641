import { FormatLocaleCurrencyOptions, formatMoney } from "../..";

export interface MoneyData {
  /**
   * The integer value of the amount
   */
  integer: number;
  /**
   * The currency code
   */
  currency: string;
  /**
   * The formatted value of the amount
   */
  value: string;
}

interface MoneyOperations {
  /**
   * Is zero
   */
  isZero: boolean;
  /**
   * Has positive value
   */
  isPositive: boolean;
  /**
   * Add the amount to the current value
   */
  add(amount: number): Money;

  /**
   * Subtract the amount from the current value
   */
  subtract(amount: number): Money;

  /**
   * Multiply the current value by the count
   */
  times(count: number): Money;

  /**
   * Calculate the percentage of the current value
   */
  percentage(amount: number): Money;
}

export class Money implements MoneyData, MoneyOperations {
  data: Omit<MoneyData, "currencyValue">;

  constructor(
    private amount: number,
    private options: FormatLocaleCurrencyOptions = {
      rounded: true,
    }
  ) {
    this.data = formatMoney(this.amount, this.options);
  }

  get integer() {
    return this.data.integer;
  }

  get currency() {
    return this.data.currency;
  }

  get value() {
    return this.data.value;
  }

  get currencyValue(): string {
    return this.data.currency + this.data.value;
  }

  get isZero() {
    return this.data.integer === 0;
  }

  get isPositive() {
    return this.data.integer > 0;
  }

  add(amount: number | Money) {
    let total: number;

    if (typeof amount === "number") {
      total = this.amount + amount;
    } else {
      total = this.amount + amount.integer;
    }

    return new Money(total, this.options);
  }

  subtract(amount: number | Money) {
    let total: number;

    if (typeof amount === "number") {
      total = this.amount - amount;
    } else {
      total = this.amount - amount.integer;
    }

    return new Money(total, this.options);
  }

  times(count: number) {
    const total = this.amount * count;

    return new Money(total, this.options);
  }

  percentage(amount: number) {
    const total = this.amount * (amount / 100);

    return new Money(total, this.options);
  }
}

export default Money;
