"use client";

import { MutableRefObject, useEffect, useRef, useState } from "react";

import {
  Html5Qrcode,
  Html5QrcodeCameraScanConfig,
  Html5QrcodeScannerState,
} from "html5-qrcode";
import { Html5QrcodeError } from "html5-qrcode/esm/core";

export type UseScannerReturnType = {
  resumeScanner: () => void;
};

export interface UseScannerInterface {
  readerRef: MutableRefObject<HTMLDivElement | undefined>;
  isCameraOpen: boolean;
  config?: {
    cameraIdOrConfig: string | MediaTrackConstraints;
    configuration: Html5QrcodeCameraScanConfig | undefined;
  };
  onSuccess: (decodedValue: string) => void;
  onError?: (errorMessage: string, error: Html5QrcodeError) => void;
}

export const useScanner = ({
  isCameraOpen,
  readerRef,
  config = {
    cameraIdOrConfig: { facingMode: "environment" },
    configuration: { fps: 3, qrbox: { width: 250, height: 250 } },
  },
  onSuccess,
  onError,
}: UseScannerInterface): UseScannerReturnType => {
  const { cameraIdOrConfig, configuration } = config;

  const html5QrCodeRef = useRef<Html5Qrcode | undefined>();
  const [isCameraShown, setIsCameraShown] = useState(false);

  const getCameraState = () => {
    return html5QrCodeRef.current?.getState();
  };

  const handleSuccess = (decodedText: string) => {
    onSuccess(decodedText);

    pauseScanning();
  };

  const handleError = (errorMessage: string, error: Html5QrcodeError) => {
    if (onError) onError(errorMessage, error);
  };

  const openCameraScanner = () => {
    const isCameraInitialized =
      getCameraState() === Html5QrcodeScannerState.NOT_STARTED;

    if (isCameraInitialized) {
      html5QrCodeRef.current?.start(
        cameraIdOrConfig,
        configuration,
        handleSuccess,
        handleError
      );
    }
  };

  const pauseScanning = () => {
    const isCameraStateScanning =
      getCameraState() === Html5QrcodeScannerState.SCANNING;

    if (isCameraStateScanning) {
      html5QrCodeRef.current?.pause();
    }
  };

  const closeCamera = () => {
    const isCameraStateScanning =
      getCameraState() === Html5QrcodeScannerState.SCANNING;
    const isCameraStatePaused =
      getCameraState() === Html5QrcodeScannerState.PAUSED;

    if (isCameraStateScanning || isCameraStatePaused) {
      html5QrCodeRef.current?.stop();
    }
  };

  const resumeScanner = () => {
    const isCameraStatePaused =
      getCameraState() === Html5QrcodeScannerState.PAUSED;

    if (isCameraStatePaused) {
      html5QrCodeRef.current?.resume();
    }
  };

  useEffect(() => {
    try {
      if (isCameraOpen && readerRef.current) {
        /*
         * there is issue in react when using html5-qrcode.
         * the issue is mainly about canvas seems to be initialized twice.
         *
         * in order to tackle this issue, need to create state to check if
         * camera already initialized or not.
         *
         * issue can be see here:
         * https://github.com/mebjas/html5-qrcode/issues/641
         */
        if (isCameraShown) return;
        setIsCameraShown(true);

        // initialize html5Qrcode.
        html5QrCodeRef.current = new Html5Qrcode(readerRef.current.id);

        openCameraScanner();
      } else {
        closeCamera();
        setIsCameraShown(false);
      }
    } catch (err) {
      console.error(err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCameraOpen, readerRef.current]);

  return {
    resumeScanner,
  };
};

export default useScanner;
