"use client";

import { classNames } from "@saas/shared/utils";

import { Typography } from "..";

import { Switch } from "@headlessui/react";

export interface ToggleProps {
  /**
   * Label for the toggle.
   * This is required for accessibility and testing.
   */
  label: string;
  /**
   * Form name for the toggle.
   * Should only be used if the toggle is used in a form.
   */
  name?: string;
  /**
   * Checked state for the toggle.
   * @default false
   */
  checked?: boolean;
  /**
   * Value for the toggle when used in a form.
   */
  value?: string;
  /**
   * Handler for when the toggle is toggled.
   */
  onChange?: (checked: boolean) => void;
  /**
   * Show the label for the toggle.
   * @default true
   */
  showLabel?: boolean;
  /**
   * Disabled state for the toggle.
   */
  disabled?: boolean;
  testid?: string;
}

export const Toggle = ({
  label,
  checked = false,
  value,
  name,
  showLabel = true,
  onChange,
  disabled,
  testid = "",
}: ToggleProps) => {
  return (
    <Switch.Group>
      <div className={"flex w-fit items-center"}>
        <Switch
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          className={classNames(
            "relative inline-flex h-5 w-8 items-center rounded-full transition-colors focus:outline-none focus:ring-0 disabled:cursor-not-allowed md:h-6 md:w-11",
            checked
              ? "bg-blue-B700 disabled:bg-blue-B400"
              : "bg-neutral-N400 disabled:bg-neutral-N200"
          )}
          data-testid={testid}
        >
          <span
            className={classNames(
              "bg-neutral-N0 inline-block h-3.5 w-3.5 transform rounded-full transition-transform md:h-4 md:w-4",
              checked
                ? "translate-x-[0.9rem] md:translate-x-6"
                : "translate-x-1"
            )}
          />
        </Switch>
        <Switch.Label
          className={classNames("ml-2", !showLabel && "sr-only")}
          data-testid={testid + "__label"}
        >
          <Typography
            type={"body-b2"}
            className={classNames("text-he", disabled && "text-disabled")}
          >
            {label}
          </Typography>
        </Switch.Label>
      </div>
    </Switch.Group>
  );
};

export default Toggle;
