"use client";

import { classNames } from "@saas/shared/utils";

export interface CounterBubbleProps {
  counter: number;
  testid?: string;
  className?: string;
  isShowCounter?: boolean;
}

export const CounterBubble = ({
  counter,
  testid,
  className,
  isShowCounter = true,
}: CounterBubbleProps) => {
  const counterElement = (
    <span className={"label-small text-invert"}>
      {counter > 99 ? (
        <>
          99<span className={"hidden md:inline-block"}>+</span>
        </>
      ) : (
        counter
      )}
    </span>
  );

  return (
    <div
      className={classNames(
        "bg-red-R700 flex items-center justify-center rounded-full border border-white font-semibold",
        counter > 99 ? "h-6 w-6" : "h-4 w-4",
        className
      )}
      data-testid={testid}
    >
      {isShowCounter ? counterElement : <div />}
    </div>
  );
};

export default CounterBubble;
