"use client";

import { DependencyList, useEffect, useMemo } from "react";

export type UseUnloadReturnType = void;

/**
 * Hook to handle unload event on window.
 * Useful for handling unsaved changes to avoid
 * user from navigating away.
 */
export const useUnload = (
  callback: (e: BeforeUnloadEvent) => void,
  deps?: DependencyList
): UseUnloadReturnType => {
  const unloadCallback = useMemo(() => callback, [callback]);

  useEffect(() => {
    window.addEventListener("beforeunload", unloadCallback);

    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, [unloadCallback, deps]);
};

export default useUnload;
