"use client";

import { createElement, forwardRef, HTMLAttributes, ReactNode } from "react";

import { classNames } from "@saas/shared/utils";

enum TypographyType {
  DISPLAY_SUPERDUPER_LARGE = "display-superduper-large",
  DISPLAY_LARGE = "display-large",
  DISPLAY_SMALL = "display-small",
  HEADLINE_H0 = "headline-h0",
  HEADLINE_H1 = "headline-h1",
  HEADLINE_H2 = "headline-h2",
  TITLE_LARGE = "title-large",
  TITLE_SEMI_MEDIUM = "title-semi-medium",
  TITLE_MEDIUM = "title-medium",
  TITLE_SMALL = "title-small",
  BODY_B1 = "body-b1",
  BODY_B1_SEMIBOLD = "body-b1-semibold",
  BODY_B2 = "body-b2",
  BODY_B2_SEMIBOLD = "body-b2-semibold",
  BODY_B2_UNDERLINE = "body-b2-underline",
  BODY_B3 = "body-b3",
  BODY_B3_SEMIBOLD = "body-b3-semibold",
  LABEL_LARGE = "label-large",
  LABEL_MEDIUM = "label-medium",
  LABEL_SMALL = "label-small",
  BUTTON_LARGE = "button-large",
  BUTTON_SMALL = "button-small",
}

const typeClass = {
  [TypographyType.DISPLAY_SUPERDUPER_LARGE]:
    "text-5xl font-extrabold leading-[3rem] tracking-[1.2px] md:text-[3.5rem] md:leading-[4.13rem]",
  [TypographyType.DISPLAY_LARGE]:
    "text-3xl md:text-[3rem] font-extrabold md:leading-[4rem] leading-[2.75rem]",
  [TypographyType.DISPLAY_SMALL]:
    "text-[2rem] md:text-[2.25rem] font-extrabold leading-[2.75rem]",
  [TypographyType.HEADLINE_H0]:
    "text-[1.3rem] md:text-[1.5rem] leading-[1.5rem] md:leading-[1.8rem]",
  [TypographyType.HEADLINE_H1]:
    "text-[1.75rem] md:text-[2rem] font-bold leading-[2.5rem]",
  [TypographyType.HEADLINE_H2]:
    "text-[1.25rem] md:text-[1.5rem] font-bold leading-[2rem]",
  [TypographyType.TITLE_LARGE]:
    "text-[1.125rem] md:text-[1.375rem] font-bold leading-[1.75rem] md:leading-[1.75rem]",
  [TypographyType.TITLE_MEDIUM]: "text-base font-bold tracking-[0.15px]",
  [TypographyType.TITLE_SEMI_MEDIUM]:
    "text-[1.125rem] font-bold leading-[2rem]  tracking-[0.25px]",
  [TypographyType.TITLE_SMALL]:
    "text-[0.875rem] font-bold leading-[1.25rem] tracking-[0.1px]",
  [TypographyType.BODY_B1]: "text-base font-normal tracking-[0.15px]",
  [TypographyType.BODY_B1_SEMIBOLD]:
    "text-base font-semibold tracking-[0.15px]",
  [TypographyType.BODY_B2]:
    "text-[0.875rem] font-normal leading-[1.25rem] tracking-[0.25px]",
  [TypographyType.BODY_B2_SEMIBOLD]:
    "text-[0.875rem] font-semibold leading-[1.25rem] tracking-[0.25px]",
  [TypographyType.BODY_B2_UNDERLINE]:
    "text-[0.875rem] font-semibold leading-[1.25rem] tracking-[0.25px] underline",
  [TypographyType.BODY_B3]:
    "text-[0.75rem] font-normal leading-[1rem] tracking-[0.4px]",
  [TypographyType.BODY_B3_SEMIBOLD]:
    "text-[0.75rem] font-semibold leading-[1rem] tracking-[0.4px]",
  [TypographyType.LABEL_LARGE]:
    "text-[0.875rem] font-medium leading-[1.5rem] tracking-[0.1px]",
  [TypographyType.LABEL_MEDIUM]:
    "text-[0.75rem] font-medium leading-[1rem] tracking-[0.5px]",
  [TypographyType.LABEL_SMALL]:
    "text-[0.625rem] font-medium leading-[1rem] tracking-[0.5px]",
  [TypographyType.BUTTON_LARGE]:
    "text-[0.875rem] font-bold leading-[1.25rem] tracking-[1.25px]",
  [TypographyType.BUTTON_SMALL]:
    "text-[0.75rem] font-medium leading-[1rem] tracking-[1.25px]",
};

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  /**
   * The type of typography to use.
   * @default body-b1
   */
  type?: `${TypographyType}`;
  /**
   * The HTML element to render as.
   * @default span
   */
  as?: keyof HTMLElementTagNameMap;
  className?: string;
  children: ReactNode;
}

/**
 * @deprecated
 */
export const Typography = forwardRef(
  (
    {
      type = TypographyType.BODY_B1,
      as: Element = "span",
      className,
      children,
      ...props
    }: TypographyProps,
    ref
  ) => {
    const attrs = {
      ...(props as HTMLAttributes<HTMLElementTagNameMap[typeof Element]>),
    };

    return createElement(
      Element,
      {
        ref,
        className: classNames(typeClass[type], className),
        ...attrs,
      },
      children
    );
  }
);

Typography.displayName = "Typography";

export default Typography;
