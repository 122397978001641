import { CashierPaymentInterface, OrderStatusEnum } from "../../";

import dayjs from "dayjs";

export type GetOfflineOrderStatusInput = CashierPaymentInterface;

export type GetOfflineOrderStatusOutput = OrderStatusEnum;

export const getOfflineOrderStatus = ({
  paymentMethod,
  invoiceDueAt,
}: GetOfflineOrderStatusInput): GetOfflineOrderStatusOutput => {
  if (paymentMethod === "invoice") {
    if (dayjs().isAfter(invoiceDueAt, "milliseconds")) {
      return OrderStatusEnum.OUTSTANDING_OVERDUE;
    }

    return OrderStatusEnum.OUTSTANDING;
  }

  return OrderStatusEnum.COMPLETED;
};

export default getOfflineOrderStatus;
