import { PromoLabelEnum } from "../..";

export const getPromoLabelDescription = (
  input: PromoLabelEnum
): string | null => {
  switch (input) {
    case PromoLabelEnum.MINIMUM_ORDER_QUANTITY:
      return "Jumlah Pembelian Barang Minimum";
    case PromoLabelEnum.MINIMUM_ORDER_SALES_AMOUNT:
      return "Nominal Pembelian Minimum";
    case PromoLabelEnum.PRODUCT_BUY_N_GET_M:
    case PromoLabelEnum.PRODUCT_BUNDLING:
      return "Pembelian Produk Khusus";
    case PromoLabelEnum.PRODUCT_DISCOUNT:
      return "Promosi Per Produk";
    case PromoLabelEnum.PAYMENT_METHOD:
      return "Promo Pembayaran";
    default:
      return null;
  }
};

export default getPromoLabelDescription;
