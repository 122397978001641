import { fetchJson, ResponseInterface } from "@saas/core";
import {
  MarketplaceConnectionInterface,
  MarketplaceConnectionMetadataInterface,
  MarketplaceListStatusEnum,
  MarketplaceTypeEnum,
} from "@saas/marketplace-connection/utils";
import { ChannelEnum, cleanPayload, mapToCamelCase } from "@saas/shared/utils";

import { stringify } from "qs";

export interface MarketplaceConnectionProvider
  extends MarketplaceConnectionInterface {
  channel: ChannelEnum.TOKOPEDIA | ChannelEnum.SHOPEE | ChannelEnum.TIKTOK;
}
interface GetMarketplaceConnectionListResponse extends ResponseInterface {
  data: ReadonlyArray<{
    id: number;
    channel: ChannelEnum;
    shop_id: string;
    shop_name: string;
    shop_url: string;
    status: MarketplaceListStatusEnum;
    is_notify: boolean;
    shop_label: string;
    is_first_connected: boolean;
    created_at: Date;
  }>;
  metadata: {
    connection_list: ReadonlyArray<{
      channel: ChannelEnum;
      status: MarketplaceListStatusEnum;
      maximum_quota: number;
      current_quota: number;
      connection_type_list: ReadonlyArray<{
        shop_id: string;
        shop_connection: string;
        chat_connection: string;
      }>;
    }>;
  };
}

export type GetMarketplaceConnectionListInput = {
  connectionType?: Array<MarketplaceTypeEnum>;
};

export type GetMarketplaceConnectionListOutput = {
  data: Array<MarketplaceConnectionInterface>;
  metadata: {
    connectionList: Array<MarketplaceConnectionMetadataInterface>;
  };
};

export const getMarketplaceConnections = async (
  input?: GetMarketplaceConnectionListInput,
  init?: RequestInit
): Promise<GetMarketplaceConnectionListOutput> => {
  const queryString = stringify(
    cleanPayload({
      connection_type: input?.connectionType,
    }),
    { arrayFormat: "comma" }
  );

  const response = await fetchJson<GetMarketplaceConnectionListResponse>(
    `/api/marketplace/marketplace-connections?` + queryString,
    init
  );

  return mapToCamelCase({ data: response.data, metadata: response.metadata });
};

export default getMarketplaceConnections;
