"use client";

import { QueryKey } from "@saas/account/config";
import { getOryCsrf } from "@saas/account/utils";
import { FetchError, queryClient } from "@saas/core";
import { QueryOptionsInterface } from "@saas/shared/utils";

import { kratos } from "../..";

import { VerificationFlow } from "@ory/client";
import { useQuery } from "@tanstack/react-query";

export type UseAccountVerificationQueryData = VerificationFlow;

export const useAccountVerificationQuery = <
  DerivedQueryData = UseAccountVerificationQueryData
>(
  options?: QueryOptionsInterface<
    UseAccountVerificationQueryData,
    DerivedQueryData
  >
) => {
  return useQuery<
    UseAccountVerificationQueryData,
    FetchError,
    DerivedQueryData
  >(
    [QueryKey.ACCOUNT_VERIFICATION],
    () => kratos.createBrowserVerificationFlow().then(({ data }) => data),
    {
      select: options?.select,
      enabled: options?.enabled,
    }
  );
};

export const getAccountVerificationFormData = ({
  id,
  ui,
}: VerificationFlow) => ({
  id,
  csrfToken: getOryCsrf(ui.nodes),
});

export const useSendVerificationQuery = (enabled: boolean) => {
  return useAccountVerificationQuery({
    select: getAccountVerificationFormData,
    enabled: enabled,
  });
};

export const invalidateAccountVerificationQuery = () => {
  return queryClient.invalidateQueries([
    /* query key */
  ]);
};

export default useAccountVerificationQuery;
