"use client";

import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";

import {
  sendVerificationEmail,
  useSendVerificationQuery,
} from "@saas/account/data-access";
import { SessionProfile } from "@saas/account/utils";
import {
  unverifiedRoutesPOSWhitelist,
  unverifiedRoutesWhitelist,
} from "@saas/config/dashboard";
import { env } from "@saas/config/shared";
import { eventGA } from "@saas/core";
import { useFlagStatus } from "@saas/flags/feature";
import { BaseButton, ContainedModal } from "@saas/shared/ui";

import useSessionProfile from "../@hook/session-profile/use-session-profile";

import { useMediaQuery } from "usehooks-ts";

export const UnverifiedAccountBlockerModalContent = ({
  profile,
}: {
  profile?: SessionProfile | null;
}) => {
  const { data: flow } = useSendVerificationQuery(!profile?.isVerified);
  const router = useRouter();
  const [sentEmail, setSentEmail] = useState(false);
  const isStandaloneView = useMediaQuery("(display-mode: standalone)");
  const isPOSPWA = isStandaloneView && env.APP_URL.includes(env.POS_URL);

  const title = sentEmail ? "Link Sudah Terkirim" : "Verifikasi Akun Kamu";

  const body = sentEmail
    ? "Cek email-mu dan lakukan verifikasi akun melalui email yang telah dikirimkan oleh Melaka."
    : "Untuk mengakses halaman ini, harap lakukan verifikasi akunmu terlebih dahulu melalui email yang kamu daftarkan di Melaka.";

  const sendVerification = async () => {
    if (!flow || !profile) return;

    const { state } = await sendVerificationEmail({
      flowId: flow.id,
      email: profile.email,
      csrfToken: flow.csrfToken,
    });

    if (state === "sent_email") {
      eventGA({
        name: "Resend Verification",
        params: {
          category: "User Account",
          action: "click_resend_verification_link",
        },
      });
    }

    setSentEmail(true);
  };

  const navigateToHome = () => {
    if (isPOSPWA) {
      navigator.share({ url: env.DASHBOARD_URL });
      return;
    }
    router.push("/");
  };

  return (
    <div className={"grid gap-6 md:text-center"}>
      <Image
        width={300}
        height={200}
        src={"/images/unverified.svg"}
        alt={"Belum ada toko online terkoneksi icon"}
      />

      <span
        className={"text-he title-large"}
        data-testid={
          sentEmail
            ? "verification-link-sent-popup__text__title"
            : "unverified-popup__text__title"
        }
      >
        {title}
      </span>
      <span
        className={"text-he body-b1"}
        data-testid={
          sentEmail
            ? "verification-link-sent-popup__text__content"
            : "unverified-popup__text__content"
        }
      >
        {body}
      </span>
      <div
        className={
          "flex flex-col items-stretch justify-center gap-4 md:items-center"
        }
      >
        {sentEmail ? null : (
          <BaseButton
            variant={"primary"}
            className={"md:w-fit"}
            onClick={sendVerification}
            testid={"unverified-popup__button__resend_link"}
          >
            Kirim Ulang Link Verifikasi
          </BaseButton>
        )}
        <BaseButton
          variant={"secondary"}
          className={"md:hidden"}
          onClick={navigateToHome}
          testid={
            sentEmail
              ? "verification-link-sent-popup__button__back_to_beranda"
              : "unverified-popup__button__back_to_beranda"
          }
        >
          Kembali ke Beranda
        </BaseButton>
      </div>
    </div>
  );
};

export const UnverifiedAccountBlockerModal = () => {
  const router = useRouter();

  const fixesFlag = useFlagStatus("SAAS-12196");

  const { profile } = useSessionProfile();
  const isPOS = env.APP_URL.includes(env.POS_URL);

  const isMarketplaceConnections = useMemo(() => {
    const routerAsPath = router.asPath;
    return routerAsPath === "/account/marketplace-connections";
  }, [router.asPath]);

  const isOpen = useMemo(() => {
    const path = "/" + router.asPath.split("/")[1];
    let whitelisted = false;

    if (isPOS) {
      whitelisted = unverifiedRoutesPOSWhitelist.some(
        (route) => route === path
      );
    } else {
      whitelisted = unverifiedRoutesWhitelist.some((route) => route === path);
    }

    return whitelisted ? false : !profile?.isVerified;
  }, [router.asPath, isPOS, profile?.isVerified]);

  const isUnverifiedAndMarketplaceConnections = useMemo(() => {
    return !profile?.isVerified && isMarketplaceConnections;
  }, [profile?.isVerified, isMarketplaceConnections]);

  const handleOpenModal = fixesFlag
    ? isOpen || isUnverifiedAndMarketplaceConnections
    : isOpen;

  return (
    <div>
      <ContainedModal open={handleOpenModal} size={"medium"}>
        <UnverifiedAccountBlockerModalContent profile={profile} />
      </ContainedModal>
    </div>
  );
};

export default UnverifiedAccountBlockerModal;
