import { Money } from "@saas/shared/utils";

import { PromoRuleTypeEnum, StorePromoSchemaInterface } from "..";

export type GetVoucherInvalidRulesOutput = string;

export const getVoucherInvalidRules = (
  schemas: ReadonlyArray<StorePromoSchemaInterface>
): GetVoucherInvalidRulesOutput => {
  const [firstSchema] = schemas;
  const [firstRule] = firstSchema.rules;

  const getMinTransaction = (minPrice: number) => {
    const price = new Money(minPrice, {
      rounded: true,
    });

    return `${price.currency} ${price.value}`;
  };

  switch (firstRule?.label) {
    case PromoRuleTypeEnum.MINIMUM_ORDER_SALES_AMOUNT:
      return firstRule?.config.minimumQuantity
        ? `Oops! Min. transaksi ${getMinTransaction(
            firstRule.config.minimumQuantity
          )} untuk menggunakan kode voucher ini.`
        : "Oops! Penuhi min. transaksi untuk menggunakan kode voucher ini.";
    // TODO: handle remaining case if needed
    default:
      return "Oops! Penuhi min. transaksi untuk menggunakan kode voucher ini.";
  }
};

export default getVoucherInvalidRules;
