"use client";

export interface FeatureFlagsQueryKey {
  data: () => readonly ["feature-flags/data"];
  status: () => readonly ["feature-flags/status"];
  logs: () => readonly ["feature-flags/logs"];
}

export const featureFlagsQueryKey: FeatureFlagsQueryKey = {
  data: () => ["feature-flags/data"] as const,
  status: () => ["feature-flags/status"] as const,
  logs: () => ["feature-flags/logs"] as const,
};

export default featureFlagsQueryKey;
