export enum OrderStatusEnum {
  WAITING_PAYMENT_BUYER = "waiting_payment_buyer",
  WAITING_PAYMENT_3PP = "waiting_payment_3pp",
  PAID = "paid",
  WAITING_3PL = "waiting_3pl",
  ACCEPTED = "accepted",
  WAITING_AWB_INPUT = "waiting_awb_input",
  INVALID_AWB_INPUT = "invalid_awb_input",
  REJECTED_AWB_INPUT = "rejected_awb_input",
  WAITING_FOR_SHIPMENT = "waiting_for_shipment",
  SHIPPING = "shipping",
  SHIPPING_ARRIVED_TO_BE_PICKUP = "shipping_arrived_to_be_pickup",
  SHIPPING_ARRIVED = "shipping_arrived",
  COMPLETED = "completed",
  OUTSTANDING_PASSED = "outstanding_passed",
  OUTSTANDING = "outstanding",
  OUTSTANDING_OVERDUE = "outstanding_overdue",
  SHIPPING_DISPUTE = "shipping_dispute",
  FRAUD_REVIEW = "fraud_review",
  CANCELLED_BY_FRAUD = "cancelled_by_fraud",
  RETURN = "return",
  CANCELLED = "cancelled",
  CANCELLED_SELLER = "cancelled_seller",
  CANCELLED_OUT_OF_STOCK = "cancelled_out_of_stock",
  RETURNED = "returned",
  CREATED = "created",
  REQUEST_CANCEL = "request_cancel",
  IN_CANCEL = "in_cancel",
  REQUEST_RETURN = "request_return",
  IN_RETURN = "in_return",
  SHIPPING_PROBLEM = "shipping_problem",
  HAS_RETURN = "has_return",
}

export default OrderStatusEnum;
