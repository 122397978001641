export type UsetifulUserRole = "NON_SDET" | "SDET";

export const initUsetiful = (
  USETIFUL_TOKEN: string,
  userId: string,
  userRole: UsetifulUserRole
) => {
  if (USETIFUL_TOKEN) {
    const userSegmentationSnippet = getUserSegmentationSnippet(
      userId,
      userRole
    );
    const userSegmentationScript = getUserSegmentationScript(
      userSegmentationSnippet
    );
    document.body.appendChild(userSegmentationScript);

    const startUsetifulSnippet = getStartUsetifulSnippet(USETIFUL_TOKEN);
    const startUsetifulScript = getStartUsetifulScript(startUsetifulSnippet);
    document.body.appendChild(startUsetifulScript);
  }
};

const getUserSegmentationSnippet = (
  userId: string,
  userRole: UsetifulUserRole
): string => {
  return `window.usetifulTags = { userId : '${userId}', role: '${userRole}',}`;
};

const getUserSegmentationScript = (snippet: string): HTMLElement => {
  const userSegmentationScript = document.createElement("script");
  userSegmentationScript.innerHTML = snippet;
  return userSegmentationScript;
};

const getStartUsetifulSnippet = (USETIFUL_TOKEN: string): string => {
  return `
    (function (w, d, s) {
      var a = d.getElementsByTagName('head')[0];
      var r = d.createElement('script');
      r.async = 1;
      r.src = s;
      r.setAttribute('id', 'usetifulScript');
      r.dataset.token = "${USETIFUL_TOKEN}";
      a.appendChild(r);
    })(window, document, "https://www.usetiful.com/dist/usetiful.js");`;
};

const getStartUsetifulScript = (snippet: string): HTMLElement => {
  const startUsetifulScript = document.createElement("script");
  startUsetifulScript.innerHTML = snippet;
  return startUsetifulScript;
};
